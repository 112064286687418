/*
 * Given a color in hex convert it to RGBA components.
 * @sig hexToRgba :: String -> {r: Number, g: Number, b: Number, a: number}
 */
const hexToRgba = hex => {
    // Ensure the hex starts with #
    if (hex[0] === '#') {
        hex = hex.slice(1)
    }

    // Expand shorthand hex (#RGBA) to full hex (#RRGGBBAA)
    if (hex.length === 4) {
        hex = hex
            .split('')
            .map(char => char + char)
            .join('')
    }

    // Extract R, G, B, A values
    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)
    const a = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) / 255 : 1 // Alpha channel (normalized to 0-1)

    return { r, g, b, a }
}

export default hexToRgba
