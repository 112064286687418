/*
 * Style a span using stitches
 */
import hexToHsl from '@range.io/basic-types/src/math/hex-to-hsl.js'
import { mergeRight } from '@range.io/functional'
import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'

const StyledText = styled('span', {
    fontFamily: '$default',
    lineHeight: '1.5',
    margin: '0',
    fontWeight: 500,
    fontVariantNumeric: 'tabular-nums',
    display: 'block',

    variants: {
        ellipsed: {
            true: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
        inherit: {
            true: {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontFamily: 'inherit',
                fontWeight: 'inherit',
                color: 'inherit',
            },
        },
    },
})

const Text = props => {
    let { limitLinesOfTextTo, css = {}, ...otherProps } = props

    css = limitLinesOfTextTo
        ? mergeRight(css, {
              overflow: 'hidden',
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': limitLinesOfTextTo,
          })
        : css

    return <StyledText css={css} {...otherProps} />
}

// ---------------------------------------------------------------------------------------------------------------------
/*
 * Small, medium-weight Text
 */
const SmallText = props => {
    const { css = {}, children } = props
    const l = { fontSize: '12px', fontWeight: 'medium', color: '$neutral05', pr: '4px' }
    return <Text css={mergeRight(l, css)}>{children}</Text>
}

/*
 * Large, medium-weight Text
 */
const LargeText = props => {
    const { css = {}, children } = props
    const l = { fontSize: '14px', fontWeight: 'medium', color: '$neutral05' }
    return <Text css={mergeRight(l, css)}>{children}</Text>
}

// ---------------------------------------------------------------------------------------------------------------------
const StyledRichTextMark = styled('mark', {
    color: '$primary04',
    backgroundColor: 'transparent',
    cursor: 'pointer',
})

const StyledRichTextSpan = styled('span', {
    color: '$neutral04',
    backgroundColor: 'transparent',
})

/*
 * Replace @mentions with <mark>'s and everything else with <span>'s:
 * text "a @a" => <span>a</span> <mark>@a</mark>
 */
const RichText = ({ text, css = {}, ...otherProps }) => {
    const Mark = StyledRichTextMark
    const Span = StyledRichTextSpan

    const generateHtmlForToken = (t, i) => (t[0] === '@' ? <Mark key={i}>{t}</Mark> : <Span key={i}>{t}</Span>)

    css = mergeRight({ fw: 400 }, css)

    const tokens = text.split(/(@\S+)/)
    return (
        <LargeText css={css} {...otherProps}>
            {tokens.map(generateHtmlForToken)}
        </LargeText>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
const LozengeText = ({ statusName, css = {} }) => {
    const { h, s, l } = hexToHsl(statusName.color)
    const backgroundColor = `hsla(${h}, ${s}%, ${l}%, 1.0)`

    css = mergeRight(
        {
            fontSize: '12px',
            color: '$white',
            margin: '0 4px 0 0',
            borderRadius: '80px',
            padding: '2px 8px',
            fontWeight: 'medium',
            whiteSpace: 'nowrap',
            backgroundColor,
        },
        css
    )

    return <Text css={css}>{statusName.name}</Text>
}

/*
 * Variation of Text that's a link
 */
const Link = styled(Text, {
    color: '$primary04',
    fontSize: '12px',
    fontWeight: 'medium',
    cursor: 'pointer',
    pr: '6px',
})

// ---------------------------------------------------------------------------------------------------------------------
SmallText.displayName = 'SmallText'
SmallText.propTypes = {
    children: PropTypes.node.isRequired,
}

// ---------------------------------------------------------------------------------------------------------------------
LargeText.displayName = 'LargeText'
LargeText.propTypes = {
    children: PropTypes.node.isRequired,
}

// ---------------------------------------------------------------------------------------------------------------------
Link.displayName = 'Link'
Link.propTypes = {
    children: PropTypes.node.isRequired,
}

// ---------------------------------------------------------------------------------------------------------------------
RichText.displayName = 'Rich Text'
RichText.propTypes = {
    text: PropTypes.string.isRequired,
}
// ---------------------------------------------------------------------------------------------------------------------
LozengeText.displayName = 'Lozenge Text'
LozengeText.propTypes = {
    statusName: PropTypes.object.isRequired,
}

export { Text, LargeText, LozengeText, RichText, SmallText, Link }
