/*
 * An Avatar is *either* an image or text in a circle or rounded square
 * Wrapper for radix-ui that adds variants for our use
 *
 * - shape: round, square
 * - size:  32, 40
 * - color: gray, seconday01
 *
 * A Radix Avatar has this structure:
 *
 *   AvatarRoot
 *     AvatarImage
 *     AvatarFallback
 *
 */

import * as AvatarPrimitive from '@radix-ui/react-avatar'
import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import { Flex, FlexRow } from './Flex.js'
import Icon from './Icon.js'
import Tooltip from './Tooltip.js'

const AvatarRoot = styled(AvatarPrimitive.Root, {
    fontFamily: '$default',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    userSelect: 'none',
    fw: 600,
    zIndex: 1,
    boxSizing: 'border-box',

    variants: {
        shape: {
            round: { borderRadius: '100%' },
            square: { borderRadius: '10px' },
        },
        size: {
            20: {
                width: '20px',
                height: '20px',
                minHeight: '20px',
                minWidth: '20px',
                maxWidth: '20px',
                maxHeight: '20px',
            },
            24: {
                width: '24px',
                height: '24px',
                minHeight: '24px',
                minWidth: '24px',
                maxWidth: '24px',
                maxHeight: '24px',
            },
            28: {
                width: '28px',
                height: '28px',
                minHeight: '28px',
                minWidth: '28px',
                maxWidth: '28px',
                maxHeight: '28px',
            },
            30: {
                width: '30px',
                height: '30px',
                minHeight: '30px',
                minWidth: '30px',
                maxWidth: '30px',
                maxHeight: '30px',
            },
            32: {
                width: '32px',
                height: '32px',
                minHeight: '32px',
                minWidth: '32px',
                maxWidth: '32px',
                maxHeight: '32px',
            },
            40: {
                width: '40px',
                height: '40px',
                minHeight: '40px',
                minWidth: '40px',
                maxWidth: '40px',
                maxHeight: '40px',
            },
            48: {
                width: '48px',
                height: '48px',
                minHeight: '48px',
                minWidth: '48px',
                maxWidth: '48px',
                maxHeight: '48px',
            },
        },
        color: {
            gray: { backgroundColor: '$neutral08', color: '$neutral05', border: '1px solid $neutral07' },
            secondary01: { backgroundColor: '$secondary01', color: '$white' },
            green03: { backgroundColor: '$green03', color: '$white' },
        },
    },
    defaultVariants: {
        shape: 'round',
        size: '32',
        color: 'secondary01',
    },
})

/*
 * AvatarPrimitive.Image
 */
const AvatarImage = styled(AvatarPrimitive.Image, {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
})

/*
 * AvatarPrimitive.Fallback with additional variants
 */
const AvatarFallback = styled(AvatarPrimitive.Fallback, {
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    variants: {
        size: {
            20: { fontSize: '11px', fontWeight: 600 },
            24: { fontSize: '11px', fontWeight: 600 },
            32: { fontSize: '16px', fontWeight: 600 },
            40: { fontSize: '18px', fontWeight: 600 },
        },
    },
})

const StyledOnlineIndicator = styled('span', {
    ai: 'center',
    height: '8px',
    width: '8px',
    br: 999,
    b: '1px solid $neutral10',
    position: 'absolute',
    bottom: '1px',
    left: 0,
    zIndex: 1,

    variants: {
        isOnline: {
            true: { background: '$green03' },
            false: { background: '$neutral05' },
        },
    },
    defaultVariants: {
        isOnline: false,
    },
})

const iconSizeMap = {
    20: '11px',
    24: '14px',
    32: '16px',
    40: '18px',
}

// TODO: / is prepended before every URL in here to allow working with routing. Should be removed once we host the avatar URLs in DB

/*
 * Avatar WITHOUT a tooltip. Pass in url and/or fallbackText.
 * participantShape is optional, but if present will override url and fallbackText
 */
const Avatar = React.forwardRef(
    ({ participantShape, url, fallbackText, shape = 'round', size = '32', color = 'gray', css, isOnline }, ref) => {
        url = participantShape?.avatarUrl || url
        fallbackText = participantShape?.avatarFallbackText || fallbackText

        return (
            <FlexRow css={{ position: 'relative' }} ref={ref}>
                <AvatarRoot css={css} shape={shape} size={size} color={color}>
                    {url && <AvatarImage src={url} alt={fallbackText} size={size} />}
                    <AvatarFallback size={size}>
                        {fallbackText || (
                            <Icon name="user" iconSize={iconSizeMap[size]} css={{ color, margin: 'auto' }} />
                        )}
                    </AvatarFallback>
                </AvatarRoot>
                {isOnline !== undefined && <StyledOnlineIndicator isOnline={isOnline} />}
            </FlexRow>
        )
    }
)

/*
 * Avatar with Tooltip: wraps an Avatar in a tooltip
 * participantShape is optional, but if present will override url and fallbackText
 */
const AvatarWithTooltip = ({
    align = 'center',
    color = 'gray',
    delayDuration = 300,
    fallbackText,
    isOnline,
    offset = 20,
    participantShape,
    shape,
    side = 'right',
    sideOffset = 8,
    size,
    tooltipText,
    url,
    ...otherTooltipOptions
}) => {
    url = participantShape?.avatarUrl || url
    fallbackText = participantShape?.avatarFallbackText || fallbackText
    const tooltipOptions = { tooltipText, side, ...otherTooltipOptions }

    return (
        <Tooltip {...tooltipOptions}>
            <Flex>
                <Avatar
                    participantShape={participantShape}
                    url={url}
                    fallbackText={fallbackText}
                    shape={shape}
                    size={size}
                    color={color}
                    isOnline={isOnline}
                />
            </Flex>
        </Tooltip>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Avatar API
// ---------------------------------------------------------------------------------------------------------------------
Avatar.displayName = 'Avatar'

// either url or fallbackText is required
// prettier-ignore
Avatar.propTypes = {
    participantShape    : PropTypes.object,
    url          : PropTypes.string,
    fallbackText : PropTypes.string,
    size         : PropTypes.string,
    shape        : PropTypes.string,
    color        : PropTypes.string,
}

// ---------------------------------------------------------------------------------------------------------------------
// AvatarWithTooltip API
// ---------------------------------------------------------------------------------------------------------------------
AvatarWithTooltip.displayName = 'AvatarWithTooltip'

// either url or fallbackText is required
// prettier-ignore
AvatarWithTooltip.propTypes = {
    participantShape    : PropTypes.object,
    url          : PropTypes.string,
    fallbackText : PropTypes.string,
    tooltipText  : PropTypes.string.isRequired,
    side         : PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    size         : PropTypes.string,
    shape        : PropTypes.string,
    color        : PropTypes.string,
    
    align        : PropTypes.oneOf(['start', 'center', 'end']),
    offset       : PropTypes.number,
    sideOffset   : PropTypes.number,
    delayDuration: PropTypes.number,
}

export { Avatar, AvatarWithTooltip }
