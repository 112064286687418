import React, { useState } from 'react'
import { styled } from '../range-theme/index.js'
import * as Popover from '@radix-ui/react-popover'

const ColorPickerTrigger = styled('button', {
    border: '1px solid $neutral07',
    width: 20,
    h: 20,
    br: '50%',
})

const ColorsContainer = styled('div', {
    w: 155,
    border: '1px solid $neutral07',
    background: '$neutral09',
    br: 6,
    padding: 8,
    display: 'grid',
    gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
    gap: 8,
})

const Color = styled('button', {
    border: '1px solid $neutral07',
    br: '50%',
    width: 20,
    height: 20,
    cursor: 'pointer',

    '&:hover': {
        borderColor: '$white',
    },
})

const ColorPicker = ({ color, onChange, colorPalette }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
            <Popover.Trigger asChild>
                <ColorPickerTrigger style={{ backgroundColor: color }} />
            </Popover.Trigger>
            {isOpen && (
                <Popover.Content
                    side="bottom"
                    align="start"
                    sideOffset={8}
                    style={{
                        zIndex: 999,
                        height: 'auto',
                    }}
                >
                    <ColorsContainer>
                        {colorPalette.map(c => (
                            <Color
                                key={c}
                                style={{ backgroundColor: c, boxShadow: color === c ? '#fff 0px 0px 0px 1px' : 'none' }}
                                onClick={() => {
                                    onChange(c)
                                    setIsOpen(false)
                                }}
                            />
                        ))}
                    </ColorsContainer>
                </Popover.Content>
            )}
        </Popover.Root>
    )
}

export default ColorPicker
