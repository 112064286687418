import { DateFormat, Geometry, TaskListFilterSettings } from '@range.io/basic-types'
import { uniq, without } from '@range.io/functional'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { FlexRow } from '../components-reusable/Flex.js'
import useBulkSelectMode from '../components-reusable/hooks/useBulkSelectMode.js'
import { useNavigateToCollaboration } from '../components-reusable/hooks/useNavigateToCollaboration.js'
import useRecentCollaborations from '../components-reusable/hooks/useRecentCollaborations.js'
import {
    CollaborationChangedCommand,
    GeometriesChangedCommand,
    GeometriesRemovedCommand,
} from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { convertIdsToTagsObjects, saveAs } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import CollaborationWindow from './CollaborationWindow.js'
import ControlBar from './ControlBar.js'
import ListViewMain from './ListViewMain'
import ProjectPage, { PageContent } from './ProjectPage.js'

const StyledCollaborationWindowContainer = styled(FlexRow, {
    minWidth: '500px',
    width: '500px',
    height: '100%',
    marginLeft: '12px',
    boxSizing: 'border-box',
})

/**
 * Sort items by field or subfield.
 * @param  {Array} items
 * @param  {Object} sortSettings {field, isAscending}
 * @param  {string?} innerField
 * @return {Array} the items array sorted
 */
const sortByField = ({ items, sortSettings, innerField }) => {
    const { field, isAscending } = sortSettings
    const isActuallyANumber = str => !isNaN(parseFloat(str)) // check if string can be turned to a number

    return items.sort((a, b) => {
        const valA = innerField ? a[field]?.[innerField] : a[field]
        const valB = innerField ? b[field]?.[innerField] : b[field]

        // some fields are stored as strings, but actually are numbers, i.e. identifiers
        // so those shouldn't be compared as strings
        if (typeof valA === 'string' && !isActuallyANumber(valA)) {
            // Explicitly check for undefined values
            if (typeof valA === 'undefined') return isAscending ? -1 : 1
            if (typeof valB === 'undefined') return isAscending ? 1 : -1

            // Perform case insensitive comparison
            if (isAscending) {
                return valA.toLowerCase().localeCompare(valB.toLowerCase())
            } else {
                return -valA.toLowerCase().localeCompare(valB.toLowerCase())
            }
        } else {
            if (!valA) return isAscending ? -1 : 1
            if (!valB) return isAscending ? 1 : -1
            if (isAscending) return valA - valB
            return valB - valA
        }
    })
}

const convertToCSV = jsonData => {
    const array = [Object.keys(jsonData[0])].concat(jsonData)
    return array
        .map(row => {
            return Object.values(row)
                .map(value => `"${value}"`)
                .join(',')
        })
        .join('\n')
}

export const LIST_VIEW_SORT_TYPES = {
    taskName: 'name',
    assigneeName: 'assignee',
    dueDate: 'dueDate',
    locationName: 'sheetLabel',
    creationDate: 'date',
    identifier: 'identifier',
}

const DEFAULT_SORT_SETTINGS = { field: 'mostRecentUpdateDate', isAscending: false }

const ListView = () => {
    const { runCommand } = useCommandHistory()
    const { getState, dispatch } = useStore()

    const allCanvases = useSelector(ReduxSelectors.canvasLookupTable)
    const selectedCollaboration = useSelector(ReduxSelectors.selectedCollaboration)
    const collaborationUpdates = useSelector(ReduxSelectors.recentCollaborationUpdateLookup)
    const allCollaborations = useSelector(ReduxSelectors.collaborationLookupTable)
    const projectParticipantShapes = useSelector(ReduxSelectors.selectedProjectParticipantShapesAsArray)
    const currentUserId = ReduxSelectors.selectedUserId(getState())
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const user = selectedOrganization?.participants[currentUserId]
    const tagNameLookup = useSelector(ReduxSelectors.tagNameLookupTable)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)

    const navigate = useNavigate()
    const { workspaceId: organizationId, projectId } = useParams()
    const { navigateToCollaboration } = useNavigateToCollaboration()
    const filteredTaskList = useSelector(ReduxSelectors.filteredTaskList)
    const [searchPhrase, setSearchPhrase] = useState(null)
    const [checkedItems, setCheckedItems] = useState([])
    const [bulkActionSelectedAssignee, setBulkActionSelectedAssignee] = useState({})
    const [areAllCheckedTasksArchived, setAreAllCheckedTasksArchived] = useState(false)
    const [bulkActionSelectedStatus, setBulkActionSelectedStatus] = useState()
    const [sortSettings, setSortSettings] = useState(DEFAULT_SORT_SETTINGS)
    const [isFollowingInProgress, setIsFollowingInProgress] = useState(false)
    const taskRowCheckboxDataId = 'task-row-checkbox'
    const isInBulkSelectMode = useBulkSelectMode()

    const { registerCollaborationAsRecentlyViewed } = useRecentCollaborations()

    // check if current user is allowed to archive geometries
    const hasRightsToArchive = useSelector(ReduxSelectors.isUpdateAllowed('geometry'))

    const canFullyUpdateCollaboration = useMemo(() => {
        if (!Object.keys(allCollaborations).length) return false

        if (!checkedItems.length) {
            const collaboration = Object.values(allCollaborations)[0]
            return ReduxSelectors.isUpdateAllowed('collaboration', collaboration)(getState())
        } else {
            const collaboration = allCollaborations[checkedItems[0]]
            return ReduxSelectors.isUpdateAllowed('collaboration', collaboration)(getState())
        }
    }, [checkedItems, allCollaborations])

    const allStatusNames = useSelector(ReduxSelectors.statusNames)
    const availableStatuses = useMemo(() => {
        const sortedStatuses = Object.values(allStatusNames)
            .sort((a, b) => a.order - b.order) // sort statuses by order
            .reduce((acc, status) => ({ ...acc, [status.id]: status }), {})

        if (canFullyUpdateCollaboration) return sortedStatuses

        return Object.keys(sortedStatuses).reduce((acc, statusId) => {
            acc[statusId] = { ...sortedStatuses[statusId] }

            // mark statuses that complete collaboration as disabled
            if (acc[statusId].isCompleted) {
                acc[statusId].disabled = true
            }

            return acc
        }, {})
    }, [allStatusNames, user, canFullyUpdateCollaboration])

    const [lastClickedCheckboxId, setLastClickedCheckboxId] = useState(false)

    // Filter out suspended users and sort alphabetically
    const allAvailableAssigneesShapes = projectParticipantShapes
        .filter(participant => !participant.isSuspended)
        .sort((a, b) => a.name?.localeCompare(b.name))

    // Check if any filters were applied
    const filterSettings = useSelector(ReduxSelectors.taskListFilterSettings)
    const { statuses: allStatusShapes, projectParticipants: allParticipantShapes } = useSelector(
        ReduxSelectors.allShapes
    )
    const project = useSelector(ReduxSelectors.selectedProject)

    const areFiltersApplied = !TaskListFilterSettings.isSetToDefaults(
        filterSettings,
        allStatusShapes,
        allParticipantShapes
    )

    const itemsToDisplay = useMemo(() => {
        const presentParticipants = ReduxSelectors.presentProjectParticipants(getState())
        const enrichItemToShape = item => {
            const state = getState()
            const tagNames = item.tags.map(tag => tag.name)
            const canvas = Object.values(allCanvases).filter(canvasShape => canvasShape.id === item.canvasId)[0]
            const assignee = item.assignee
                ? ReduxSelectors.selectedProjectParticipantWithId(state, item.assignee?.id)
                : null
            const assigneeIsOnline = !!presentParticipants.find(u => u.id === item.assignee?.id)
            const projectIdentifier = ReduxSelectors.selectedProject(state)?.identifier

            return {
                ...item,
                assigneeAvatarUrl: assignee?.avatarUrl,
                assigneeEmail: assignee?.email,
                assigneeIsOnline,
                assigneeName: item.assignee?.fullName,
                mostRecentUpdateDate: collaborationUpdates[item.id],
                sheetLabel: canvas.name,
                tagNames,
                projectIdentifier,
                canvasId: canvas.id,
            }
        }

        const items = Object.values(filteredTaskList)
        const itemShapes = items.map(enrichItemToShape)
        const filterBySearchPhrase = item => item.name.toLowerCase().includes(searchPhrase)
        const itemsFiltered = searchPhrase?.length ? itemShapes.filter(filterBySearchPhrase) : itemShapes

        let innerField = null
        // ensure the assignee is sorted by fullName, not by comparing assignees objects
        if (sortSettings.field === LIST_VIEW_SORT_TYPES.assigneeName) innerField = 'fullName'

        const itemsSorted = sortByField({ items: itemsFiltered, sortSettings, innerField })
        return itemsSorted
    }, [collaborationUpdates, filteredTaskList, sortSettings, searchPhrase])

    // Adjust checked items when the list changes
    // e.g. after search or filter
    useEffect(() => {
        if (!checkedItems.length) return

        const itemsMap = new Map()
        itemsToDisplay.forEach(item => itemsMap.set(item.id, item))

        const checkedItemsNew = []

        // Check if checked items contain item id that is displayed on the list.
        // If it is no longer displayed (e.g. after search is used),
        // that element should be removed from checked items.
        checkedItems.forEach(itemId => {
            if (itemsMap.get(itemId)) checkedItemsNew.push(itemId)
        })

        setCheckedItems(checkedItemsNew)
    }, [itemsToDisplay])

    const handleSearchPhraseChanged = newSearchPhrase => {
        if (newSearchPhrase?.length) setSearchPhrase(newSearchPhrase.trim().toLowerCase())
        else setSearchPhrase(newSearchPhrase)
    }

    const handleGoToProjectCanvas = () => navigate(`/${organizationId}/${projectId}`)

    const handleItemSelect = itemId => {
        dispatch(ReduxActions.collaborationSelected({ id: itemId }))
        registerCollaborationAsRecentlyViewed(itemId)
    }

    const handleGoToCanvas = () => navigateToCollaboration(selectedCollaboration.id)

    const handleGoToCanvasId = collaborationId => navigateToCollaboration(collaborationId)

    const handleItemChecked = itemId => {
        setCheckedItems(checkedItemsArray => {
            const _idsToToggle = () => {
                const allIdsArray = itemsToDisplay.map(obj => obj.id)

                // Get index of most recently checked/unchecked checkbox on the rows list
                const lastClickedItemIndex = allIdsArray.findIndex(id => id === lastClickedCheckboxId)

                // Get index of currently checked/unchecked item on the rows list
                const currentItemIndex = allIdsArray.findIndex(id => id === itemId)

                // Get all items between two points
                if (lastClickedItemIndex > currentItemIndex)
                    return allIdsArray.slice(currentItemIndex, lastClickedItemIndex)

                return allIdsArray.slice(lastClickedItemIndex, currentItemIndex)
            }

            // Check if item isn't already present in checked items.
            // If it isn't, it means user just checked it and we have to add it to checked items.
            const shouldAddItems = checkedItemsArray.indexOf(itemId) === -1

            if (!isInBulkSelectMode && shouldAddItems) return [...checkedItemsArray, itemId] // if we are not in bulk select mode, simply add new item as checked
            if (!isInBulkSelectMode) return without(itemId, checkedItemsArray)

            const idsToToggle = _idsToToggle()

            // Add all idsToToggle to the original checked items
            if (shouldAddItems) return uniq([...checkedItemsArray, ...idsToToggle, itemId, lastClickedCheckboxId])

            // uncheck all items in between last clicked checkbox and the checkbox that was clicked right now
            return without([...idsToToggle, itemId, lastClickedCheckboxId], checkedItemsArray)
        })

        setLastClickedCheckboxId(itemId)
        setBulkActionSelectedStatus(null)
        setBulkActionSelectedAssignee(null)
    }

    const clearCheckedItems = () => {
        setCheckedItems([])
        setBulkActionSelectedStatus(null)
        setBulkActionSelectedAssignee(null)
    }

    const showToast = ({ toastLabel, severity = 'success' }) => {
        dispatch(
            ReduxActions.toastAdded({
                id: checkedItems[0],
                toastLabel,
                severity,
                showUndo: false,
            })
        )
    }

    const getGeometryForTaskId = itemId => {
        const collaboration = allCollaborations[itemId]
        if (!collaboration) return null

        return ReduxSelectors.geometryForCollaboration(getState(), collaboration)
    }

    const isAnyCheckedTaskUnarchived = items =>
        items?.some(itemId => {
            const geometry = getGeometryForTaskId(itemId)
            return !!geometry.archivedDate === false
        })

    useEffect(() => {
        setAreAllCheckedTasksArchived(!isAnyCheckedTaskUnarchived(checkedItems))
    }, [checkedItems])

    const onDeleteItems = () => {
        // Go through each checked task id and remove its corresponding geometry
        const geometriesToDelete = []
        checkedItems?.forEach(itemId => {
            const geometry = getGeometryForTaskId(itemId)
            if (!geometry) return
            geometriesToDelete.push(geometry)
        })

        const itemsCount = geometriesToDelete.length
        const toastLabel =
            itemsCount > 1
                ? `${itemsCount} tasks have successfully been deleted`
                : `${itemsCount} task has successfully been deleted`

        runCommand(GeometriesRemovedCommand.Outbound(geometriesToDelete, toastLabel))
        setCheckedItems([]) // clear all selections
    }

    const onArchiveItems = () => {
        let itemsArchivedCount = 0

        checkedItems?.forEach(itemId => {
            const geometry = getGeometryForTaskId(itemId)

            // If task is already archived, skip updating it
            if (!geometry || (!areAllCheckedTasksArchived && geometry.archivedDate)) return

            const updatedGeometry = Geometry.update(geometry, {
                archivedDate: areAllCheckedTasksArchived ? undefined : new Date(), // Unarchive, when all checked tasks are already archived
            })
            itemsArchivedCount++
            runCommand(GeometriesChangedCommand.Outbound([updatedGeometry]))
        })

        setAreAllCheckedTasksArchived(!isAnyCheckedTaskUnarchived(checkedItems))

        const toastLabel =
            itemsArchivedCount > 1
                ? `${itemsArchivedCount} tasks have successfully been archived`
                : `${itemsArchivedCount} task has successfully been archived`

        showToast({ toastLabel })
    }

    const handleAssigneeChange = ({ userId, collaborationId }) => {
        if (!collaborationId) return false

        runCommand(CollaborationChangedCommand.Outbound(collaborationId, { assigneeId: userId }, currentUserId))
        return true
    }

    const onBulkActionSelectedAssignee = userId => {
        const participant = userId ? ReduxSelectors.selectedProjectParticipantWithId(getState(), userId) : null
        setBulkActionSelectedAssignee(participant)

        let changedTasks = 0

        checkedItems?.forEach(itemId => {
            const collaboration = allCollaborations[itemId]

            // Skip updating if selected user is already an assignee of this collaboration
            if (!collaboration || collaboration.assignee === userId) return

            const isSuccess = handleAssigneeChange({ collaborationId: collaboration.id, userId })
            if (isSuccess) changedTasks++
        })

        if (changedTasks) {
            const toastLabel =
                changedTasks > 1
                    ? `The Assignee for ${changedTasks} tasks has been successfully updated`
                    : `The Assignee for ${changedTasks} task has been successfully updated`

            showToast({ toastLabel })
        }
    }

    // convert the dropdown text back to a StatusName id since only the id is stored in a Collaboration
    const handleStatusChange = (collaborationId, statusId) =>
        runCommand(CollaborationChangedCommand.Outbound(collaborationId, { statusId }, currentUserId)) // update collaboration status

    const onBulkActionSelectedStatus = statusId => {
        setBulkActionSelectedStatus(statusId)

        let changedTasks = 0
        checkedItems?.forEach(itemId => {
            const collaboration = allCollaborations[itemId]
            const geometry = getGeometryForTaskId(itemId)

            // Skip updating if status is not archived and the status is already set as the one we want to switch to
            if (!geometry.archivedDate && collaboration.statusName === statusId) return

            const updatedGeometry = Geometry.update(geometry, {
                archivedDate: undefined, // unarchive task to make it visible back again
            })
            runCommand(GeometriesChangedCommand.Outbound([updatedGeometry])) // update geometry archived date
            runCommand(CollaborationChangedCommand.Outbound(collaboration.id, { statusId }, currentUserId)) // update collaboration status
            changedTasks++
        })

        const toastLabel =
            changedTasks > 1
                ? `The Status for ${changedTasks} tasks has been successfully updated`
                : `The Status for ${changedTasks} task has been successfully updated`

        showToast({ toastLabel })
    }

    const onBulkActionTagsChange = ({ items, editType }) => {
        const addTags = newTagIds => {
            checkedItems?.forEach(itemId => {
                const collaboration = allCollaborations[itemId]
                const newTags = convertIdsToTagsObjects(uniq([...collaboration.tags, ...newTagIds]), tagNameLookup)

                runCommand(
                    CollaborationChangedCommand.Outbound(
                        collaboration.id,
                        { tags: newTags },
                        currentUserId,
                        true /* skipUpdate */
                    )
                )
            })
        }

        const replaceTags = newTagIds => {
            checkedItems?.forEach(itemId => {
                const collaboration = allCollaborations[itemId]
                const newTags = convertIdsToTagsObjects(uniq(newTagIds), tagNameLookup)

                runCommand(
                    CollaborationChangedCommand.Outbound(
                        collaboration.id,
                        { tags: newTags },
                        currentUserId,
                        true /* skipUpdate */
                    )
                )
            })
        }

        if (editType === 'add') addTags(items)
        if (editType === 'replace') replaceTags(items)

        const changedTasks = checkedItems.length
        const toastLabel =
            changedTasks > 1
                ? `The Tags for ${changedTasks} tasks have been successfully updated`
                : `The Tags for ${changedTasks} task have been successfully updated`

        showToast({ toastLabel })
    }

    const handleDateChange = ({ itemId, dueDate }) => {
        const collaboration = allCollaborations[itemId]
        runCommand(CollaborationChangedCommand.Outbound(collaboration.id, { dueDate }, currentUserId))
    }

    const onBulkActionDateChange = dueDate => {
        if (!dueDate) return

        checkedItems?.forEach(itemId => {
            handleDateChange({ itemId, dueDate })
        })
        const changedTasks = checkedItems.length

        const toastLabel =
            changedTasks > 1
                ? `The Due Date for ${changedTasks} tasks has been successfully updated`
                : `The Due Date for ${changedTasks} task has been successfully updated`

        showToast({ toastLabel })
    }

    const handleCheckAllItemsClick = () => {
        if (itemsToDisplay.length === checkedItems.length) setCheckedItems([])
        else setCheckedItems([...itemsToDisplay.map(item => item.id)])
    }

    const handleSortClick = ({ type, isAscending }) => {
        if (isAscending === undefined) setSortSettings({ field: type, isAscending: true })
        else if (isAscending) setSortSettings({ field: type, isAscending: false })
        else setSortSettings(DEFAULT_SORT_SETTINGS)
    }

    const handleBulkFollowButtonClick = async () => {
        let modifiedItems = 0
        setIsFollowingInProgress(true)

        // Await all CollaborationChangedCommand.Outbound before showing the toast
        await Promise.all(
            checkedItems.map(itemId => {
                const followers = allCollaborations[itemId].followers
                const isAFollower = followers?.includes(currentUserId)

                // If not all selected tasks have current user as a follower
                // we want to modify only some to make all selected tasks followed by them.
                // In such case, we simply skip modifying the task which he is already following,
                // as there are no changes to be made.
                if (!areAllTasksFollowed && isAFollower) return Promise.resolve()

                modifiedItems++
                let newFollowers = []
                if (areAllTasksFollowed) {
                    newFollowers = without(currentUserId, followers)
                } else if (!isAFollower) {
                    newFollowers = followers.concat(currentUserId)
                }

                return runCommand(
                    CollaborationChangedCommand.Outbound(itemId, { followers: newFollowers }, currentUserId)
                )
            })
        )

        setIsFollowingInProgress(false)
        const toastLabel = areAllTasksFollowed ? `${modifiedItems} tasks unfollowed` : `${modifiedItems} tasks followed`
        showToast({ toastLabel })
    }

    /**
     * For all selected collaborations, get array of user ids and either add or remove them from collaborations followers.
     * @param {[uuid]} usersIds
     * @param {'add'|'remove'} mode
     */
    const handleBulkFollowersChange = async (usersIds, mode) => {
        setIsFollowingInProgress(true)

        // Await all CollaborationChangedCommand.Outbound before showing the toast
        await Promise.all(
            checkedItems.map(itemId => {
                const collaboration = allCollaborations[itemId]
                let newFollowers = collaboration.followers

                if (mode === 'add') {
                    newFollowers = uniq(collaboration.followers.concat(usersIds))
                } else if (mode === 'remove') {
                    newFollowers = without(usersIds, collaboration.followers)
                } else return Promise.resolve()

                return runCommand(
                    CollaborationChangedCommand.Outbound(itemId, { followers: newFollowers }, currentUserId)
                )
            })
        )

        setIsFollowingInProgress(false)
        showToast({ toastLabel: `Followers for selected tasks ${mode === 'add' ? 'added' : 'removed'}` })
    }

    const handleExport = () => {
        const buildItemForExport = itemId => {
            const item = itemsToDisplay.find(i => i.id === itemId)
            const geometry = item.geometry()
            const canvas = geometry.parent()
            const isOnSatelliteCanvas =
                canvas.canvasSources.filter(cs => cs.type === 'mapbox' || cs.type === 'dronedeploy').length > 0
            const exportObject = {
                name: item.name.length > 0 ? item.name : 'Untitled task',
                id: `${selectedProject.identifier}-${item.identifier}`,
                assignee: item.assigneeName,
                due: item.dueDate ? DateFormat.primary(item.dueDate) : '-',
                location: item.sheetLabel,
                tags: item.tags.length,
                created: DateFormat.primary(item.createdAt),
            }
            if (isOnSatelliteCanvas) {
                const formattedGeometry = Geometry.getFormattedForDisplay(geometry)
                exportObject.latitude = formattedGeometry.lat
                exportObject.longitude = formattedGeometry.lng
                if (formattedGeometry?.elevationMetres) {
                    exportObject.elevationMetres = formattedGeometry.elevationMetres
                    exportObject.elevationFeet = formattedGeometry.elevationFeet
                }
            }
            return exportObject
        }

        const dataToExport = checkedItems.map(buildItemForExport)
        const csvData = convertToCSV(dataToExport)
        const blob = new Blob([csvData], { type: 'text/csv' })
        saveAs(blob, 'exported.csv')
    }

    const areAllTasksFollowed = React.useMemo(() => {
        if (!allCollaborations || !checkedItems.length) return false

        // if user doesn't follow one of the selected collaborations
        if (checkedItems.some(itemId => !allCollaborations[itemId].followers.includes(currentUserId))) return false

        return true
    }, [checkedItems, allCollaborations])

    const hasRightsToDelete = React.useMemo(() => {
        if (!user || !allCollaborations) return false

        // check if current user can delete all selected tasks
        const canManage = checkedItems.every(collaborationId =>
            ReduxSelectors.isDeleteAllowed('collaboration', allCollaborations[collaborationId])(getState())
        )

        return currentUserId && canManage
    }, [checkedItems, allCollaborations, currentUserId, user])

    const isProjectDataLoaded = ReduxSelectors.isProjectDataLoaded(getState())

    useEffect(() => {
        if (project) {
            const segmentParams = ReduxSelectors.paramsForTrackEvent(getState())
            const archiveItemCount = itemsToDisplay.filter(item => item.isArchived).length
            segmentParams.activeTaskCount = itemsToDisplay.length - archiveItemCount
            segmentParams.archiveTaskCount = archiveItemCount
            segmentParams.projectName = project.name
            Segment.sendTrack('project tasklist viewed', v4(), segmentParams)
        }
    }, [project, itemsToDisplay])

    return (
        <ProjectPage data-cy="list-view" activeTab={ControlBar.TABS.LIST}>
            <PageContent css={{ flexDirection: 'row' }}>
                <ListViewMain
                    hasRightsToArchive={hasRightsToArchive}
                    hasRightsToDelete={hasRightsToDelete}
                    isProjectDataLoaded={isProjectDataLoaded}
                    items={itemsToDisplay}
                    onGoToCanvas={handleGoToProjectCanvas}
                    onGoToCanvasId={handleGoToCanvasId}
                    onItemSelect={handleItemSelect}
                    showFullContent={!selectedCollaboration}
                    statusNames={availableStatuses}
                    areFiltersApplied={areFiltersApplied}
                    handleSearchPhraseChanged={handleSearchPhraseChanged}
                    checkedItems={checkedItems}
                    handleItemChecked={handleItemChecked}
                    clearCheckedItems={clearCheckedItems}
                    onDeleteItems={onDeleteItems}
                    onArchiveItems={onArchiveItems}
                    allAvailableAssigneesShapes={allAvailableAssigneesShapes}
                    bulkActionSelectedAssignee={bulkActionSelectedAssignee}
                    onBulkActionSelectedAssignee={onBulkActionSelectedAssignee}
                    areAllCheckedTasksArchived={areAllCheckedTasksArchived}
                    bulkActionSelectedStatus={bulkActionSelectedStatus}
                    onBulkActionSelectedStatus={onBulkActionSelectedStatus}
                    onBulkActionTagsChange={onBulkActionTagsChange}
                    onBulkActionDateChange={onBulkActionDateChange}
                    onCheckAllItemsClick={handleCheckAllItemsClick}
                    onSortClick={handleSortClick}
                    sortSettings={sortSettings}
                    onDateChange={handleDateChange}
                    onAssigneeChange={handleAssigneeChange}
                    onBulkFollowButtonClick={handleBulkFollowButtonClick}
                    onBulkActionFollowersChange={handleBulkFollowersChange}
                    onStatusChange={handleStatusChange}
                    areAllTasksFollowed={areAllTasksFollowed}
                    isFollowingInProgress={isFollowingInProgress}
                    taskRowCheckboxDataId={taskRowCheckboxDataId}
                    onExport={handleExport}
                />
                {selectedCollaboration && (
                    <StyledCollaborationWindowContainer>
                        <CollaborationWindow onGoToCanvas={handleGoToCanvas} />
                    </StyledCollaborationWindowContainer>
                )}
            </PageContent>
        </ProjectPage>
    )
}

export default ListView
