import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'

// prettier-ignore
const StatusShape = tagged('StatusShape', {
    id         : StringTypes.Id,
    
    color      : StringTypes.Color,
    isCompleted: 'Boolean',  // does this status represent a "completed" workflow?
    isInitial  : 'Boolean',  // is this the status a Collaboration should start in?
    name       : 'String',
    order      : 'Number?',
})

// prettier-ignore
const StatusShapePropTypes = {
    id         : PropTypes.string.isRequired,
    
    color      : PropTypes.string.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    isInitial  : PropTypes.bool.isRequired,
    name       : PropTypes.string.isRequired,
    order      : PropTypes.number,
}

StatusShape.fromStatusName = shapeLookupTable => statusName =>
    StatusShape.from({
        id: statusName.id,

        color: statusName.color,
        isCompleted: statusName.isCompleted,
        isInitial: statusName.isInitial,
        name: statusName.name,
        order: statusName.order,
    })

export { StatusShape, StatusShapePropTypes }
