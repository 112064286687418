import * as TabMenuPrimitive from '@radix-ui/react-toggle-group'
import { styled } from '@stitches/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Text } from './index.js'

const StyledTabMenu = styled(TabMenuPrimitive.Root, {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    gap: '24px',
    height: '100%',
})

const StyledItem = styled(TabMenuPrimitive.Item, {
    all: 'unset',
    backgroundColor: '$neutral10',
    color: '$neutral05',
    height: 'auto',
    width: 'fit-content',
    display: 'flex',
    fontSize: 15,
    lineHeight: 1.5,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    transitionDuration: '0.4s',
    cursor: 'pointer',
    '&:first-child': {
        marginLeft: 0,
    },

    '&:last-child': {},

    '&:hover': {
        color: '$primary04',
    },

    '&[data-state=on]': {
        color: '$primary04',
    },

    '&:focus': {
        position: 'relative',
    },
})

const BottomHorizontalBar = styled(Box, {
    width: '100%',
    height: '3px',
    borderRadius: '100px 100px 0 0',
    marginTop: '10px',

    variants: {
        selected: {
            true: { backgroundColor: '$primary04' },
        },
    },
})

/*
 * TabMenu component that allows the user to select between a number of options in a tab-like menu.
 * Manages its' selected index internally, but exposes an escape hatch of `controlledIndex` that allows
 * interference from outside and has precedence over local one.
 */
const TabMenu = ({ controlledIndex = null, css, initialIndex = 0, items, onChange }) => {
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)

    const _onChange = i => {
        setSelectedIndex(i)
        onChange?.(i)
    }

    const getSelectedIndex = () => (controlledIndex !== null ? controlledIndex : selectedIndex)

    const item = (item, i) => (
        <StyledItem key={item.label} type="single" value={item.label} aria-label={item.label} data-cy={item.id}>
            <Text css={{ fw: 500, fs: '14px' }} onClick={() => _onChange(i)}>
                {item.label}
                <BottomHorizontalBar selected={getSelectedIndex() === i} />
            </Text>
        </StyledItem>
    )

    return (
        <StyledTabMenu type="single" value={items[getSelectedIndex()].label} aria-label="Text alignment" css={css}>
            {items.map(item)}
        </StyledTabMenu>
    )
}

TabMenu.displayName = 'TabMenu'

// either url or fallbackText is required
// prettier-ignore
TabMenu.propTypes = {
    items           : PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired})).isRequired,
    onChange        : PropTypes.func.isRequired,
    initialIndex    : PropTypes.number,
    css             : PropTypes.object,
    controlledIndex : PropTypes.number
}

export default TabMenu
