import { StatusName } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux'
import CommandPlayer from './command-player'

const StatusNameRemovedCommand = taggedSum('StatusNameRemovedCommand', {
    Inbound: {
        statusName: 'StatusName',
    },
})

const runInboundCommand = (resources, command) => {
    const { dispatch } = resources
    const { statusName } = command
    dispatch(ReduxActions.statusNameRemoved(statusName))
    dispatch(ReduxActions.removeStatusNameFromFilterSettings({ statusName }))
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        StatusNameRemovedCommand,
        CommandPlayer({
            CommandType: StatusNameRemovedCommand,
            Type: StatusName,
            collectionPath: projectId => `/projects/${projectId}/statusNames/`,
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'removed',
        })
    )
}

export { addCommand, StatusNameRemovedCommand }
