import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box-without-terminal'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { draggable, dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { StatusName } from '@range.io/basic-types'
import { assoc, mergeRight } from '@range.io/functional'
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'react-redux'
import invariant from 'tiny-invariant'
import { v4 } from 'uuid'
import {
    Button,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    Text,
    TextInput,
    Tooltip,
} from '../components-reusable/index.js'
import { STATUS_COLOR_PALETTE, styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import ColorPicker from './ColorPicker.js'

const DragAreaIcon = () => (
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2.5" r="2" fill="#424D66" />
        <circle cx="2" cy="9.5" r="2" fill="#424D66" />
        <circle cx="2" cy="16.5" r="2" fill="#424D66" />
        <circle cx="9" cy="2.5" r="2" fill="#424D66" />
        <circle cx="9" cy="9.5" r="2" fill="#424D66" />
        <circle cx="9" cy="16.5" r="2" fill="#424D66" />
    </svg>
)

const StyledLabel = styled(Text, {
    fs: 12,
    fw: 500,
    color: '$neutral05',
})

const StatusSection = styled(FlexColumn, {
    padding: 8,
    gap: 8,
    backgroundColor: '$neutral09',
})

const InputArea = styled('div', {
    border: '1px solid $neutral07',
    br: 6,
    gap: 8,
    backgroundColor: '$neutral10',
})

const SortableStatus = ({ status, index, handleColorChange, handleDelete, handleNameChange, instanceId }) => {
    const ref = useRef(null)
    const [dragging, setDragging] = useState(false)
    const isFixed = status.isInitial || status.isCompleted

    const [state, setState] = useState({ type: 'idle' })

    useEffect(() => {
        const el = ref.current
        if (isFixed) return

        invariant(el)
        return combine(
            draggable({
                element: el,
                onDragStart: () => setDragging(true),
                onDrop: () => setDragging(false),
                getInitialData() {
                    return { type: 'item-row', status, index, instanceId }
                },
            }),
            dropTargetForElements({
                element: el,
                canDrop({ source }) {
                    return (
                        source.data.instanceId === instanceId &&
                        source.data.type === 'item-row' &&
                        source.data.item !== status
                    )
                },
                getData({ input, element }) {
                    const data = { status, index }
                    return attachClosestEdge(data, {
                        input,
                        element,
                        allowedEdges: ['top', 'bottom'],
                    })
                },
                onDragEnter(args) {
                    setState({
                        type: 'is-over',
                        closestEdge: extractClosestEdge(args.self.data),
                    })
                },
                onDrag(args) {
                    const closestEdge = extractClosestEdge(args.self.data)

                    // only update react state if the `closestEdge` changes
                    setState(current => {
                        if (current.type !== 'is-over') {
                            return current
                        }
                        if (current.closestEdge === closestEdge) {
                            return current
                        }
                        return {
                            type: 'is-over',
                            closestEdge,
                        }
                    })
                },
                onDragLeave() {
                    setState({ type: 'idle' })
                },
                onDrop() {
                    setState({ type: 'idle' })
                },
            })
        )
    }, [])

    const css = isFixed
        ? {}
        : {
              br: 6,
              p: '8px 12px 8px 8px',
              border: '1px solid $neutral07',
              backgroundColor: '$neutral09',
          }

    return (
        <FlexColumn>
            <FlexRow
                css={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    br: 6,
                    gap: 10,
                    opacity: dragging ? '0.6' : '1',
                    position: 'relative',
                    cursor: 'pointer',
                    ...css,
                }}
                ref={ref}
            >
                {!isFixed && (
                    <Flex
                        css={{
                            alignItems: 'center',
                        }}
                    >
                        <DragAreaIcon />
                    </Flex>
                )}
                <InputArea
                    style={{
                        flex: '1 0 auto',
                        height: '39px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: 8,
                    }}
                >
                    <TextInput
                        value={status.name}
                        placeholder="Status name"
                        onChange={value => handleNameChange(status, value)}
                        variant="simple"
                        noClear
                    />
                    <ColorPicker
                        color={status.color}
                        colorPalette={STATUS_COLOR_PALETTE}
                        onChange={color => handleColorChange(status.id, color)}
                    />
                </InputArea>
                {!isFixed && (
                    <IconButtonWithTooltip
                        variant="iconOnly"
                        onClick={() => handleDelete(status)}
                        iconName="trash"
                        tooltipText="Delete status"
                        side="right"
                        size="16px"
                        css={{ padding: '4px 0' }}
                    />
                )}
                {state.type === 'is-over' && state.closestEdge ? <DropIndicator edge={state.closestEdge} /> : null}
            </FlexRow>
        </FlexColumn>
    )
}

function extractIndex(data) {
    const { index } = data
    if (typeof index !== 'number') {
        return null
    }
    return index
}

const StatusEditorView = ({ statuses, onAddNew, onColorChange, onDelete, onNameChange, onReorder }) => {
    const [instanceId] = useState(() => Symbol('instance-id'))

    useEffect(() => {
        return monitorForElements({
            onDrop({ location, source }) {
                const destination = location.current.dropTargets[0]
                if (!destination) {
                    return
                }

                const startIndex = extractIndex(source.data)
                const indexOfTarget = extractIndex(destination.data)
                if (startIndex === null || indexOfTarget === null) {
                    return
                }
                onReorder(startIndex, indexOfTarget)
            },
        })
    }, [instanceId, statuses]) // statuses are needed here, because it affects the reorder logic in onReorder function

    return (
        <FlexColumn
            css={{
                border: '1px solid $neutral07',
                br: 6,
            }}
        >
            <StatusSection css={{ br: '6px 6px 0 0', borderBottom: '1px solid $neutral07' }}>
                <FlexRow
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <StyledLabel>Initial Pin Status</StyledLabel>
                    <Tooltip
                        tooltipText="The initial pin status is what a task
pin will default to when created"
                        side="bottom"
                        align="center"
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name="helpCenter" css={{ size: '20px', color: '$neutral05' }} />
                        </div>
                    </Tooltip>
                </FlexRow>
                {statuses.slice(0, 1).map(status => (
                    <SortableStatus
                        key={status.id}
                        status={status}
                        handleColorChange={onColorChange}
                        handleNameChange={onNameChange}
                        handleDelete={onDelete}
                        instanceId={instanceId}
                    />
                ))}
            </StatusSection>
            <FlexColumn style={{ gap: 8, padding: 8 }}>
                {statuses.slice(1, statuses.length - 1).map((status, index) => (
                    <SortableStatus
                        key={index + 1}
                        index={index + 1}
                        status={status}
                        handleColorChange={onColorChange}
                        handleNameChange={onNameChange}
                        handleDelete={onDelete}
                        instanceId={instanceId}
                    />
                ))}
                <Button variant="secondary" shortPadding onClick={onAddNew} size="md">
                    <Icon name="addCircled" iconSize={14} />
                    Add Status
                </Button>
            </FlexColumn>
            <StatusSection
                css={{
                    br: '0 0 6px 6px',
                    borderTop: '1px solid $neutral07',
                }}
            >
                <FlexRow
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <StyledLabel>Final Pin Status</StyledLabel>
                    <Tooltip
                        tooltipText="The final pin status is when a task
is considered done, or complete."
                        side="bottom"
                        align="center"
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name="helpCenter" css={{ size: '20px', color: '$neutral05' }} />
                        </div>
                    </Tooltip>
                </FlexRow>
                {statuses.slice(statuses.length - 1).map(status => (
                    <SortableStatus
                        key={status.id}
                        status={status}
                        handleColorChange={onColorChange}
                        handleNameChange={onNameChange}
                        handleDelete={onDelete}
                        instanceId={instanceId}
                    />
                ))}
            </StatusSection>
        </FlexColumn>
    )
}

const moveElement = (fromIndex, toIndex, array) => {
    const newArray = [...array]
    const [element] = newArray.splice(fromIndex, 1)
    newArray.splice(toIndex, 0, element)
    return newArray
}

const StatusEditor = ({ defaultStatuses = [], onStatusesChange }) => {
    const [statuses, setStatuses] = useState(defaultStatuses)
    const { dispatch, getState } = useStore()

    const NEW_STATUS_BLUEPRINT = {
        id: v4(),
        isCompleted: false,
        isInitial: false,
        name: '',
        color: '#4C6EF5',
        order: 1, // this will be overriden
    }

    useEffect(() => {
        onStatusesChange(statuses) // report statuses change
    }, [statuses])

    // find the existing StatusName with id, and replace it with a new one after applying the changes
    const _handleStatusChange = (id, changes) => {
        const newStatuses = [...statuses]
        const statusItemIndex = newStatuses.findIndex(el => el.id === id)
        const statusItem = newStatuses[statusItemIndex]
        newStatuses[statusItemIndex] = StatusName.from(mergeRight(statusItem, changes))
        setStatuses(newStatuses)
    }

    const handleNameChange = (status, name) => _handleStatusChange(status.id, { name })
    const handleColorChange = (id, color) => _handleStatusChange(id, { color })

    const _handleDelete = status => {
        const newStatuses = [...statuses].filter(s => s.id !== status.id)
        setStatuses(newStatuses)
    }

    const handleDelete = status => {
        const allCollaborations = ReduxSelectors.collaborationsAsArray(getState())
        const hasCollaborationsWithThisStatus = allCollaborations.some(c => c.statusName === status.id)
        if (hasCollaborationsWithThisStatus)
            dispatch(
                ReduxActions.globalModalDataSet({
                    type: 'destructive',
                    title: 'Are you sure you want to delete this status?',
                    description:
                        'You currently have pins using this status. Deleting this status will change all affected pins to the default status.',
                    cancelButton: {
                        label: 'Cancel',
                    },
                    submitButton: {
                        label: 'Delete',
                        onClick: () => _handleDelete(status),
                    },
                })
            )
        else _handleDelete(status)
    }

    // To change order we need to change the order field
    // and either sort or manually move item to the right position
    const handleReorder = (fromIndex, toIndex) => {
        const newItems = moveElement(fromIndex, toIndex, statuses)

        // reorder, creating new StatusNames
        const min = Math.min(fromIndex, toIndex)
        const max = Math.max(fromIndex, toIndex)
        for (let i = min; i <= max; i++) newItems[i] = StatusName.from(assoc('order', i, newItems[i]))

        setStatuses(newItems)
    }

    // We need to add a new item just before the last item
    const handleAddNew = () => {
        const last = statuses.length

        // create a new status, update the last status to have a new `order` and then add both at the end of the array
        const newStatus = StatusName.from(assoc('order', last - 1, NEW_STATUS_BLUEPRINT))
        const lastStatus = StatusName.from(assoc('order', last, statuses.at(-1)))
        const newStatuses = [...statuses.slice(0, -1), newStatus, lastStatus]

        setStatuses(newStatuses)
    }

    return (
        <StatusEditorView
            statuses={statuses}
            onAddNew={handleAddNew}
            onNameChange={handleNameChange}
            onDelete={handleDelete}
            onColorChange={handleColorChange}
            onReorder={handleReorder}
        />
    )
}

export default StatusEditor
