import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../components-reusable/hooks/index.js'
import IconButtonWithTooltip from '../components-reusable/IconButtonWithTooltip.js'
import { Avatar, Button, Flex, FlexColumn, FlexRow, Text, TextArea } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import FollowersSelector from './FollowersSelector.js'

const StyledCommentBoxTextAreaContainer = styled(FlexColumn, {
    flexGrow: '500',
    rowGap: '16px',
    borderRadius: '6px',
    backgroundColor: '$neutral09',
    padding: '8px 8px',
    justifyItems: 'stretch',
    border: '1px solid $neutral07',
    transitionDuration: '0.4s',
})

/*
 * Visual component containing a textarea and submit button.
 * Used as an entry point for the user to input a comment. Expands when user focuses on it and
 * shrinks when focus is lost (clicked outside of the whole component, used Escape or Tab keys)
 */
const CommentBox = ({
    css = {},
    allowNote,
    onCommentSubmit,
    onFocusChange,
    userAvatarUrl,
    userName,
    allowFollowersSelector,
    participantsForFollowersSelector,
    onFollowerSelected,
    followers,
}) => {
    const [isNote, setIsNote] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const [isValidComment, setIsValidComment] = useState(false)
    const textAreaRef = useRef()
    const textAreaContainerRef = useRef()
    const followersSelectorRef = useRef()
    const toggleNote = () => setIsNote(!isNote)

    useOnClickOutside([textAreaContainerRef, followersSelectorRef], () => setIsExpanded(false))

    const handleKeyUp = ({ key }) => {
        if (key === 'Tab' || key === 'Escape') {
            textAreaRef.current.blur()
            setIsExpanded(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp)
        return () => window.removeEventListener('keyup', handleKeyUp)
    }, [])

    useEffect(() => onFocusChange(isExpanded), [isExpanded])

    const handleTextAreaFocus = () => setIsExpanded(true)

    const handleValueChange = () => {
        const currentValue = textAreaRef.current?.value
        setIsValidComment(currentValue && currentValue !== '')
    }

    const handleCommentSubmit = () => {
        onCommentSubmit(textAreaRef.current.value, isNote)
        textAreaRef.current.value = ''
        textAreaRef.current.blur()
        setIsExpanded(false)
    }

    const iconName = isNote ? 'flagFilled' : 'flagOutline'

    const border = isNote ? '1px solid $orange03' : '1px solid $primary04'
    const backgroundColor = isNote ? '$orange03' : '$primary04'
    const borderHover = isNote ? '1px solid $orange02' : '1px solid $primary03'
    const backgroundHover = isNote ? '$orange01' : '$primary01'
    const backgroundFocus = isNote ? '$orange01' : '$primary01'

    return (
        <Flex
            data-cy="comment-box"
            css={{
                width: '100%',
                background: '$neutral10',
                padding: '16px 24px',
                bt: '1px solid $neutral07',
                br: '0px 0px 6px 6px',
                position: 'relative',
                zIndex: 0,
                ...css,
                ai: 'center',
            }}
        >
            <Avatar size="32" url={userAvatarUrl} fallbackText={userName[0]} css={{ marginRight: '8px' }} />
            <StyledCommentBoxTextAreaContainer
                css={{
                    '&:hover': {
                        border: borderHover,
                        background: backgroundHover,
                    },

                    '&:focus-within': {
                        border,
                        background: backgroundFocus,
                    },
                }}
                ref={textAreaContainerRef}
            >
                <TextArea
                    data-cy="comment-box-textarea"
                    placeholder={isNote ? 'Post a note' : 'Post a comment'}
                    onFocus={handleTextAreaFocus}
                    ref={textAreaRef}
                    maxLineCount={isExpanded ? 8 : 1}
                    overflow="scroll"
                    onChange={handleValueChange}
                    css={{
                        border: 'none',
                        background: 'none',
                        br: '0px',
                        lineHeight: '21px',
                        padding: '0px 0px 0px 4px',

                        '&:hover': {
                            border: 'none',
                        },

                        '&:active': {
                            border: 'none',
                        },

                        '&:focus': {
                            border: 'none',
                        },
                        '&::placeholder': { border: 'none', color: '$neutral05' },
                    }}
                />
                {isExpanded && (
                    <FlexRow css={{ gap: '4px', height: '32px', justifyContent: 'space-between' }}>
                        <FlexRow css={{ gap: '4px' }}>
                            {allowNote && (
                                <IconButtonWithTooltip
                                    css={{ color: '$neutral05', cursor: 'pointer', transitionDuration: '0.2s' }}
                                    onClick={toggleNote}
                                    iconName={iconName}
                                    tooltipText="Create Note"
                                    size="32px"
                                    side="bottom"
                                />
                            )}
                            {allowFollowersSelector && (
                                <FollowersSelector
                                    multiSelect
                                    participants={participantsForFollowersSelector}
                                    onFollowerSelected={onFollowerSelected}
                                    followers={followers}
                                    ref={followersSelectorRef}
                                />
                            )}
                        </FlexRow>
                        <Button
                            variant="primary"
                            size="md"
                            css={{
                                ml: 'auto',
                                backgroundColor,
                                '&:hover:enabled': {
                                    backgroundColor: isNote ? '$orange04' : '$primary06',
                                },
                                '&:disabled': {
                                    backgroundColor,
                                },
                            }}
                            onClick={handleCommentSubmit}
                            disabled={!isValidComment}
                        >
                            <Text data-cy="comment-button" css={{ fw: 400 }}>
                                {isNote ? 'Add note' : 'Add comment'}
                            </Text>
                        </Button>
                    </FlexRow>
                )}
            </StyledCommentBoxTextAreaContainer>
        </Flex>
    )
}

CommentBox.displayName = 'CommentBox'
CommentBox.propTypes = {
    allowNote: PropTypes.bool,
    onCommentSubmit: PropTypes.func.isRequired,
    userAvatarUrl: PropTypes.string,
    userName: PropTypes.string.isRequired,
}

/*
 * A variant of CommentBox that's used inline for editing comments
 */
CommentBox.Edit = ({ css, initialText, onCancel, onSave }) => {
    const [isAllowedToSave, setIsAllowedToSave] = useState(false)
    const textAreaRef = useRef()

    useEffect(() => {
        textAreaRef.current.value = initialText
        textAreaRef.current.focus()
    }, [initialText])

    const handleValueChange = () => {
        const currentValue = textAreaRef.current.value
        setIsAllowedToSave(currentValue !== initialText && currentValue !== '')
    }

    const handleCancelClick = () => onCancel(textAreaRef.current.value)

    const handleSaveClick = () => onSave(textAreaRef.current.value)

    return (
        <FlexRow css={css}>
            <StyledCommentBoxTextAreaContainer>
                <TextArea
                    data-cy="edit-comment-textarea"
                    placeholder="Add a comment"
                    onChange={handleValueChange}
                    ref={textAreaRef}
                    rows={2}
                    maxLineCount={8}
                    overflow="scroll"
                    css={{
                        border: 'none',
                        background: 'none',
                        br: '0px',
                        lineHeight: '21px',
                        padding: '0 4px',

                        '&:hover': {
                            border: 'none',
                        },

                        '&:active': {
                            border: 'none',
                        },

                        '&:focus': {
                            border: 'none',
                        },
                    }}
                />
                <FlexRow>
                    <Button variant="secondary" size="md" css={{ ml: 'auto' }} onClick={handleCancelClick}>
                        <Text css={{ fw: 400 }}>Cancel</Text>
                    </Button>
                    <Button
                        variant="primary"
                        size="md"
                        css={{ ml: '8px' }}
                        onClick={handleSaveClick}
                        disabled={!isAllowedToSave}
                    >
                        <Text data-cy="save-comment-button" css={{ fw: 400 }}>
                            Save
                        </Text>
                    </Button>
                </FlexRow>
            </StyledCommentBoxTextAreaContainer>
        </FlexRow>
    )
}

CommentBox.Edit.displayName = 'CommentBox'
CommentBox.Edit.propTypes = {
    initialText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

export default CommentBox
