/*
 * Dropdown selector for the status of a Collaboration.
 *
 * Based on radix-ui Select https://www.radix-ui.com/docs/primitives/components/select
 *
 *   <Select.Root>
 *       <Select.Trigger>                       <-- the button that causes the dropdown menu to appear
 *           <Select.Value />
 *           <Select.Icon />
 *       </Select.Trigger>
 *
 *       <Select.Content>                       <-- wrapper around all the items in the dropdown menu
 *           <Select.ScrollUpButton />          <-- optional up button if there are a lot of items in the menu
 *           <Select.Viewport>                  <-- wrapper around just the visible items in the menu
 *               <Select.Item>                  <-- a single item in the dropdown list
 *                   <Select.ItemText />        <-- a text item
 *                   <Select.ItemIndicator />   <-- a checkbox next to the selected item
 *               </Select.Item>
 *               <Select.Separator />
 *           </Select.Viewport>
 *           <Select.ScrollDownButton />
 *       </Select.Content>
 *   </Select.Root>
 */
import * as RadixSelect from '@radix-ui/react-select'
import { hexToHsl, StatusName } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon, Link, Tooltip } from '../components-reusable/index.js'
import { RangeUITheme, styled } from '../range-theme/index.js'

// convert the background color to HSL so we can dim it if selected
const hoverColor = bg => {
    const { h, s, l } = hexToHsl(bg)
    return `hsla(${h}, ${s}%, ${l}%, 0.8)`
}

const normalColor = bg => {
    const { h, s, l } = hexToHsl(bg)
    return `hsla(${h}, ${s}%, ${l}%, 1.0)`
}

const StyledSelectIcon = styled(RadixSelect.SelectIcon, {
    alignSelf: 'center',
    paddingTop: '4px',
    marginLeft: '4px',
})

const StyledTrigger = styled(RadixSelect.Trigger, {
    display: 'inline-flex',
    alignItems: 'center',
    border: 'none',
    borderRadius: 80,
    color: 'white',
    fontFamily: '$default',
    fontSize: '14px',
    fontWeight: 500,
    height: 32,
    outline: 'none',
    padding: '0 10px',
    cursor: 'pointer',
})

const StyledViewport = styled(RadixSelect.Viewport, {
    color: '$neutral04',
    backgroundColor: '$neutral09',
    outline: 'none',
    padding: '0 8px',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    boxShadow: `${RangeUITheme.shadows.lg}`,
})

const StyledStatusItem = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '$default',
    fontWeight: 500,
    fontSize: 14,
    padding: '7px 10px',
    outline: 'none',
    borderRadius: 999,
    gap: 4,
    color: 'white',
    transitionDuration: '0.4s',
    cursor: 'pointer',
})

const StyledRoot = styled(RadixSelect.Root, {})

const StyledItemIndicator = styled('div', {
    height: '13px',
})

const StyledGroup = styled(RadixSelect.SelectGroup, {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
    padding: '8px 0',
})

const StatusSelectorTrigger = ({ disabled, selectedStatus, displayAsList }) => {
    const css = {
        backgroundColor: selectedStatus?.color,
        cursor: disabled ? 'default' : 'pointer',
    }

    /*
     * when displayAsList is true, we want to HIDE the trigger, since our "Status" was already used as our trigger.
     * Unfortunately, simply hiding it, or not rendering it causes a bug whereby the browser complains about
     * aria-hidden going astray, so instead we can use position to move it offscreen instead
     */
    if (displayAsList) css.position = 'absolute'

    if (disabled)
        return (
            <StyledTrigger data-cy="current-status" css={css} disabled={disabled}>
                <RadixSelect.Value />
            </StyledTrigger>
        )

    return (
        <Tooltip side="bottom" tooltipText="Update Status" asChild>
            <StyledTrigger data-cy="current-status" css={css}>
                <RadixSelect.Value />
                <StyledSelectIcon>
                    <Icon name="chevronDown" iconSize="12px" />
                </StyledSelectIcon>
            </StyledTrigger>
        </Tooltip>
    )
}

// StatusItem component to display single Status pill, with a small checkmark if it's the selected one.
// If asSelectItem is set to `true`, then it turns into a single drop-down menu item of the status selector,
// that utilizes RadixSelect Item and ItemIndicator.
const StatusItem = ({ selectedStatus, status, asSelectItem = false }) => {
    const { id, disabled, color, name } = status

    const css = {
        backgroundColor: normalColor(color),
        '&:hover': { backgroundColor: hoverColor(color) },
    }

    const isSelected = selectedStatus?.id === id

    // if the status is disabled, meaning user cannot click it
    // and is not currently selected option, just skip showing it
    if (!isSelected && disabled) return null

    if (asSelectItem)
        return (
            <RadixSelect.Item key={id} css={css} value={name} bg={color} asChild>
                <StyledStatusItem>
                    <RadixSelect.SelectItemText>{name}</RadixSelect.SelectItemText>
                    <RadixSelect.ItemIndicator>
                        <StyledItemIndicator>
                            <Icon name="tickSmall" iconSize="12px" />
                        </StyledItemIndicator>
                    </RadixSelect.ItemIndicator>
                </StyledStatusItem>
            </RadixSelect.Item>
        )

    return (
        <StyledStatusItem css={css} bg={color}>
            {name}
            {isSelected && (
                <StyledItemIndicator>
                    <Icon name="tickSmall" iconSize="12px" />
                </StyledItemIndicator>
            )}
        </StyledStatusItem>
    )
}

/*
 * Dropdown menu to select the view shown in the background map
 */
const StatusSelector = ({
    onStatusChanged,
    allStatusNames,
    initialStatusName,
    defaultOpen = false,
    displayAsList = false,
    ...otherProps
}) => {
    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()

    // convert the dropdown text back to a StatusName id since only the id is stored in a Collaboration
    const _onStatusChanged = name => {
        const statusName = StatusName.withName(allStatusNames, name)
        setSelectedStatusName(statusName)
        onStatusChanged(statusName.id)
    }

    const handleEditStatusesClicked = () =>
        navigate(`/${workspaceId}/${projectId}/statuses`, { state: { safeToNavigateBack: true } })

    // all possible status choices as a dropdown menu
    const dropdownItems = (selectedStatusName, positioningAttributes) => (
        <RadixSelect.Content style={{ zIndex: 999 }} {...positioningAttributes}>
            <StyledViewport data-cy="select-viewport">
                <StyledGroup>
                    {Object.values(allStatusNames)?.map(status => (
                        <StatusItem key={status.id} selectedStatus={selectedStatusName} status={status} asSelectItem />
                    ))}
                    <Link css={{ lineHeight: '18px' }} onClick={handleEditStatusesClicked}>
                        Edit Statuses
                    </Link>
                </StyledGroup>
            </StyledViewport>
        </RadixSelect.Content>
    )

    // if the initial status name changes, update the selected status name
    useEffect(() => setSelectedStatusName(initialStatusName), [initialStatusName])

    // main ------------------------------------------------------------------------------------------------------------
    const [selectedStatusName, setSelectedStatusName] = useState(initialStatusName)
    const shouldDisplayAsDisabled = selectedStatusName?.disabled // it means user cannot change current status

    /*
     * displayAsList means that we want to use Radix's position: 'popper' functionality.
     * -32 moves the menu items up towards our 'Status' button in the bulk items button, the only place
     * we currently use displayAsList.
     *
     * https://www.radix-ui.com/primitives/docs/components/select
     *
     * By default, Select will behave similarly to a native MacOS menu by positioning Select.Content
     * relative to the active item. If you would prefer an alternative positioning approach similar to
     * Popover or DropdownMenu then you can set position to popper and make use of additional alignment
     * options such as side, sideOffset and more.
     */
    const positioningAttributes = displayAsList ? { position: 'popper', sideOffset: -32 } : {}

    return (
        <StyledRoot
            value={selectedStatusName?.name}
            onValueChange={_onStatusChanged}
            defaultOpen={defaultOpen}
            {...otherProps}
        >
            <StatusSelectorTrigger
                disabled={shouldDisplayAsDisabled}
                selectedStatus={selectedStatusName}
                displayAsList={displayAsList}
            />
            <RadixSelect.Portal>{dropdownItems(selectedStatusName, positioningAttributes)}</RadixSelect.Portal>
        </StyledRoot>
    )
}

StatusSelector.propTypes = {
    onStatusChanged: PropTypes.func.isRequired,
    allStatusNames: PropTypes.object.isRequired, // lookupTable
    initialStatusName: PropTypes.object,
    defaultOpen: PropTypes.bool,
    displayAsList: PropTypes.bool,
}

export { StatusItem }

export default StatusSelector
