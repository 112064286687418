import PropTypes from 'prop-types'
import React from 'react'
import { FlexRow } from '../components-reusable/Flex.js'
import { Text } from '../components-reusable/Text.js'
import { styled } from '../range-theme/index.js'

const StyledIdentifier = styled(FlexRow, {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    color: '$neutral05',

    variants: {
        variant: {
            small: {
                fontSize: '11px',
                lineHeight: '17px',
                fontWeight: '700',
            },
            medium: {
                fontSize: '13px',
                lineHeight: '20px',
                fontWeight: '500',
            },
        },
    },
})

const Identifier = ({ projectIdentifier, collaborationIdentifier, noPrefix = false, css, ...rest }) => {
    if (!projectIdentifier) return null

    const prefix = !noPrefix && 'ID: '
    const id = collaborationIdentifier ? `${projectIdentifier}-${collaborationIdentifier}` : projectIdentifier

    return (
        <StyledIdentifier css={css} {...rest}>
            <Text inherit>
                {prefix}
                {id}
            </Text>
        </StyledIdentifier>
    )
}

Identifier.displayName = 'Identifier'

Identifier.propTypes = {
    projectIdentifier: PropTypes.string.isRequired,
    collaborationIdentifier: PropTypes.string,
    noPrefix: PropTypes.bool,
}

export default Identifier
