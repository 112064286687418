const SHARED_COLORS = {
    // Primary (Blue)
    Primary01: '#5D81FF10', // 10% alpha
    Primary02: '#5D81FF20', // 20% alpha
    Primary03: '#5D81FF60', // 60% alpha
    Primary04: '#5D81FF', // 00% alpha
    Primary05: '#BFCDFF', // Double check where this value is being used
    Primary06: '#3E60D7',
    Primary07: '#23346F',

    // Secondary (Purple)
    Secondary01: '#9492FF',
    Secondary02: '#6F6DFC',
    Secondary03: '#4947D8',
    Secondary04: '#34328B',

    // Red (20%, 60% & 100% alpha)
    Red01: '#F13D1530',
    Red02: '#F13D1560',
    Red03: '#F13D15',
    Red04: '#C82400',
    Red05: '#452A24',

    // Green (20%, 60% & 100% alpha)
    Green01: '#009E5C20',
    Green02: '#009E5C60',
    Green03: '#009E5C',
    Green04: '#007D49',
    Green05: '#223B31',

    // Orange (20%, 60% & 100% alpha)
    Orange01: '#FF7A0020',
    Orange02: '#FF7A0060',
    Orange03: '#FF7A00',
    Orange04: '#D96800',
    Orange05: '#493522',

    // Pink (20%, 60% & 100% alpha)
    Pink01: '#F531B320',
    Pink02: '#F531B360',
    Pink03: '#F531B3',
    Pink04: '#C70585',
    Pink05: '#622E50',

    // Special
    Notifications: '#FC625D',
    StatusOnline: '#00DA7E',
    Component: '#8000FF',
    Archived: '#3a4766',
    SnapshotBackground: '#262737',

    // Misc
    White: 'white',
    Black: 'black',
}

const DARK_COLORS = {
    // Neutral Colors - White to Black (Lightmode)
    Neutral01: '#FFFFFF',
    Neutral02: '#FAFAFA',
    Neutral03: '#EEEEEE',
    Neutral04: '#DDDDDD',
    Neutral05: '#999999',
    Neutral06: '#666666',
    Neutral07: '#333333',
    Neutral08: '#282727',
    Neutral09: '#1E1E1E',
    Neutral10: '#000000',

    NeutralOverlay: '#FFFFFF20',
}

// eslint-disable-next-line no-unused-vars
const LIGHT_COLORS = {
    // Neutral Colors - White to Black (Darkmode)
    Neutral01: '#000000',
    Neutral02: '#1E1E1E',
    Neutral03: '#282727',
    Neutral04: '#333333',
    Neutral05: '#666666',
    Neutral06: '#999999',
    Neutral07: '#CCCCCC',
    Neutral08: '#EEEEEE',
    Neutral09: '#FAFAFA',
    Neutral10: '#FFFFFF',

    NeutralOverlay: '#00000020',
}

const STATUS_COLOR_PALETTE = [
    SHARED_COLORS.Primary04, // default 'in-progress' status
    '#8000FF', // default 'needs review' status
    '#EF516D', // default 'blocked' status
    SHARED_COLORS.Green03, // default 'complete' status
    SHARED_COLORS.Red03,
    '#9BC93A', // lime green
    SHARED_COLORS.Orange03,
    '#00D0BF', // turquoise
    SHARED_COLORS.Pink03,
    '#F0C400', // yellow
    '#CD0047', // pinkish-red
    '#2329DD', // blue
]

/*
 * RangeColors includes all the SHARED_COLORS and all the DARK and LIGHT colors. The DARK colors are *also*
 * copied into RangeColors, so that RangeColors.Neutral01 has an initial value from DARK_COLORS.Neutral01
 *
 * Later when the color mode changes, either LIGHT_COLORS or DARK_COLORS is copied into the RangeColors
 *
 * TODO: duplicating light, dark and current colors into RangeColors needs some more thought
 */

const RangeColors = Object.assign({}, SHARED_COLORS, DARK_COLORS, {
    dark: DARK_COLORS,
    light: LIGHT_COLORS,
})

export { STATUS_COLOR_PALETTE }

export default RangeColors
