/*
 * StatusName
 *
 * Every status in a project is actually just an index to a StatusName so we can rename a Status quickly
 *
 *
 * BEWARE: It seems like the StatusNames are returned in ID order from Firebase, which works now because
 * they are deliberately ordered that way.
 * TODO: Add a mechanism to order StatusNames that's more obvious that keeping their ids in order
 */
import { findInValues, pick, tagged } from '@range.io/functional'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const StatusName = tagged('StatusName', {
    id         : StringTypes.Id,
    name       : 'String',
    color      : StringTypes.Color,
    isInitial  : 'Boolean',  // is this the status a Collaboration should start in?
    isCompleted: 'Boolean',  // does this status represent a "completed" workflow?
    order      : 'Number',
})

// ---------------------------------------------------------------------------------------------------------------------
// Selectors
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Return the StatusName with the given name
 * @sig withName :: ([StatusName], String) -> StatusName
 */
StatusName.withName = (statusNames, name) => findInValues(st => st.name === name, statusNames)

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------
/*
 * @sig fromFirebase = {k:v} -> StatusName
 */
StatusName.fromFirebase = StatusName.from
StatusName.toFirebase = statusName => pick(['id', 'name', 'color', 'isInitial', 'isCompleted', 'order'], statusName)

export default StatusName
