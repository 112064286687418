/*
 * The entire page for the "Project Details" button
 */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSearch } from '../../components-reusable/hooks/index.js'
import {
    Button,
    DropdownMenuWithTooltip,
    FlexColumn,
    FlexRow,
    Icon,
    ScrollArea,
    Select,
    Text,
    TextAreaExternallyChangeable,
    TextInput,
} from '../../components-reusable/index.js'
import { styled } from '../../range-theme/index.js'
import SinglePlaceMap from '../SinglePlaceMap.js'
import {
    Label,
    PanelActions,
    Select300,
    StyledAddressRow,
    StyledLeftPanelBox,
    StyledLeftPanelTopBox,
    StyledLeftPanelTopBoxContent,
} from './SharedComponents.js'

const NO_ID = 'none'
const ADDRESS_CONTAINER_ID = 'mapbox-adress-search-container'
// ---------------------------------------------------------------------------------------------------------------------
// LeftPanel
// ---------------------------------------------------------------------------------------------------------------------/*

const StyledTextDescription = styled(TextAreaExternallyChangeable, {
    lineHeight: '24px',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,

    '&:focus, &:hover, &:active': {
        border: 'none',
    },
})
const StyledTextName = styled(TextInput, {
    height: '40px',
})

const StyledAddressContainer = styled(FlexRow, {
    '& > div': {
        width: '100%',
        mt: 4,
    },

    '.mapboxgl-ctrl-geocoder': {
        width: '100%',
        boxShadow: 'none',
        border: '1px solid $neutral07',
        borderRadius: 6,
        outline: 'none',
        backgroundColor: '$neutral09',
        height: '40px',
        color: '$neutral05',

        '&:focus-within': {
            border: '1px solid $primary04',
        },

        '&:hover': {
            background: '$primary01',
        },

        '.mapboxgl-ctrl-geocoder--input': {
            width: '100%',
            color: '$neutral04',
            borderRadius: 'inherit',
            height: '100%',

            '&::placeholder': {
                color: '$neutral05',
            },
        },

        '.mapboxgl-ctrl-geocoder--button': {
            background: 'none',
        },

        '.mapboxgl-ctrl-geocoder--icon-close': {
            width: '16px',
            height: '16px',
            right: '10px',
            color: '$neutral05',
        },
    },
})

const TagSelectorTrigger = styled(Button, {
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: 6,
    padding: '0 10px 0 10px',
    fontSize: 14,
    lineHeight: 1,
    gap: 5,
    backgroundColor: '$neutral09',
    border: '1px solid $neutral07',
    color: '$neutral04',
    cursor: 'pointer',
    boxShadow: '0px 0px 0px #5D81FF50',
    transitionDuration: '0.2s',

    $$hoverBackgroundColor: 'var(--colors-primary02)',
    $$hoverColor: 'var(--colors-neutral04)',

    '&:hover': {
        border: '1px solid $primary03',
    },
})

const TagsSelectorItem = ({ tag, isSelected, handleTagSelect }) => {
    return (
        <DropdownMenuWithTooltip.MenuItem
            key={tag.id}
            onSelect={() => handleTagSelect(tag)}
            onPointerLeave={event => event.preventDefault()}
            onPointerMove={event => event.preventDefault()}
        >
            <Button
                style={{ justifyContent: 'space-between' }}
                variant="dropdown-menuitem-navigator"
                data-selected={isSelected}
            >
                <Text>{tag.name}</Text>
            </Button>
        </DropdownMenuWithTooltip.MenuItem>
    )
}

const TagsSelector = ({ selectedTag, tags, inputValue, setInputValue, onTagSelected }) => {
    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger data-cy="select-project-tags">
                <TagSelectorTrigger>
                    <Text>{selectedTag?.name}</Text>
                    <Icon name="popupMenuArrowTop" iconSize="10px" />
                </TagSelectorTrigger>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content
                side="bottom"
                align="middle"
                sideOffset={6}
                loop={true}
                css={{
                    width: `var(--radix-popper-anchor-width)`,
                    maxHeight: `min(var(--radix-popper-available-height), 50vh)`,
                }}
            >
                <DropdownMenuWithTooltip.MenuItem
                    css={{ padding: 0, flex: '1 0 auto' }}
                    onSelect={e => e.preventDefault()} // prevent the dropdown from collapsing when input is clicked
                    onPointerLeave={event => event.preventDefault()}
                    onPointerMove={event => event.preventDefault()}
                >
                    <TextInput
                        data-cy="tags-search-input"
                        placeholder="Search for tags"
                        onChange={setInputValue}
                        value={inputValue}
                        onKeyDown={e => {
                            if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Escape') e.stopPropagation() // allow bubbling only for ArrowDown, ArrowUp and Escape
                        }}
                    />
                </DropdownMenuWithTooltip.MenuItem>

                <ScrollArea>
                    <FlexColumn css={{ display: 'flex', fd: 'column', gap: '8px' }} orientation="vertical">
                        {tags.length ? (
                            tags.map(tag => (
                                <TagsSelectorItem
                                    key={tag.id}
                                    tag={tag}
                                    isSelected={selectedTag?.id === tag.id}
                                    handleTagSelect={onTagSelected}
                                />
                            ))
                        ) : (
                            <Text css={{ padding: '10px', color: '$neutral05', fontSize: '14px' }}>
                                No results found
                            </Text>
                        )}
                    </FlexColumn>
                </ScrollArea>
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

/*
 * When you're CREATING a new project you probably want to CREATE canvases for your project
 */
const LeftPanel = ({
    address,
    description,
    name,
    projectType,
    projectTypeOptions,
    tagSource,
    tagSourceOptions,
    statusSourceOptions,
    statusSource,

    onCancel,
    onNext,

    nameError,
    addressError,
    projectTypeError,

    setDescription,
    setName,
    setProjectType,
    setTagSource,
    setStatusSource,
}) => {
    const projectDescriptionCss = {
        alignItems: 'top',
        gap: '4px',
        padding: '10px',
        minHeight: '88px',
        borderRadius: '6px',
        backgroundColor: '$neutral09',
        border: '1px solid $neutral07',
        transitionDuration: '0.2s',

        '&:hover': {
            border: '1px solid $primary03',
            background: '$primary01',
        },
        '&:active': {
            border: '1px solid $primary04',
            background: '$primary01',
        },
        '&:focus': {
            border: '1px solid $primary04',
            background: '$primary01',
        },
    }

    const hasProjectType = projectType && projectType !== NO_ID
    const isDataMissing = !address || !name || !hasProjectType
    const [showErrors, setShowErrors] = useState(false)

    const {
        searchValue: tagSearchValue,
        setSearchValue: setTagSearchValue,
        filteredItems: filteredTags,
    } = useSearch({
        fields: ['name'],
        items: tagSourceOptions,
    })

    const handleTagSelected = tag => {
        setTagSource(tag.id)
        setTagSearchValue('')
    }

    const css = { height: '40px', width: 'content-fit', ml: 'auto' }

    const scrollAreaHeight = '100vh'

    const selectedTag = tagSourceOptions.find(tag => tag.id === tagSource)

    return (
        <StyledLeftPanelBox>
            <StyledLeftPanelTopBox>
                <ScrollArea maxHeight={scrollAreaHeight}>
                    <StyledLeftPanelTopBoxContent>
                        <FlexRow css={{ ai: 'center', marginBottom: 24 }}>
                            <FlexRow css={{ ai: 'center', gap: 4 }}>
                                <Icon
                                    name="newProject"
                                    iconSize="24px"
                                    css={{ minWidth: '24px', height: '24px', color: '$neutral04' }}
                                />
                                <Label variant="title">Create New Project</Label>
                            </FlexRow>
                            <Button css={css} variant="secondary" size="lg" onClick={onCancel}>
                                <Text>Cancel</Text>
                            </Button>
                        </FlexRow>

                        <Label variant="heading">Project Details</Label>
                        <Label variant="tertiary">Enter the details of your project below</Label>

                        <Label variant="primary">Project Address</Label>
                        <FlexColumn css={{ mt: 0 }}>
                            <Label css={{ marginBottom: 6 }} variant="secondary">
                                Search for your address below, or select your project address by clicking on the map to
                                add a pin.
                            </Label>
                            <StyledAddressContainer>
                                <div id={ADDRESS_CONTAINER_ID} data-cy="project-address-input" />
                            </StyledAddressContainer>
                            {address && (
                                <StyledAddressRow>
                                    <Label
                                        variant="address"
                                        css={{ color: address ? '$neutral04' : '$red04', width: '100%' }}
                                    >
                                        <Icon
                                            name="projectDetails"
                                            iconSize="20px"
                                            css={{ minWidth: '20px', height: '20px', color: '$neutral05', mr: 4 }}
                                        />
                                        {address}
                                    </Label>
                                    <Icon name="check" css={{ width: 20, maxHeight: 20, color: '$green03' }} />
                                </StyledAddressRow>
                            )}
                        </FlexColumn>
                        {showErrors && addressError && <Label variant="error">{addressError} </Label>}
                        <Label variant="primary">Project Name</Label>
                        <FlexRow css={{ gap: '4px' }}>
                            <StyledTextName
                                data-cy="project-name-input"
                                placeholder="Give your project a name..."
                                initialValue={name}
                                onChange={setName}
                            />
                        </FlexRow>
                        {showErrors && nameError && <Label variant="error">{nameError} </Label>}

                        <Label variant="primary">Project Description (Optional)</Label>
                        <FlexRow css={projectDescriptionCss}>
                            <StyledTextDescription
                                data-cy="project-description-input"
                                placeholder="Give your project a description..."
                                externalValue={description}
                                onBlur={setDescription}
                                maxLineCount={8}
                            />
                        </FlexRow>

                        <Label variant="primary">Project Type</Label>
                        <Label variant="secondary" css={{ mb: '10px' }}>
                            Select the project type below
                        </Label>
                        <FlexRow css={{ gap: '4px', maxHeight: '40px', height: '40px' }}>
                            <Select300
                                data-cy="select-project-type"
                                options={projectTypeOptions}
                                value={projectType}
                                onValueChange={setProjectType}
                            />
                        </FlexRow>
                        {showErrors && projectTypeError && <Label variant="error">{projectTypeError} </Label>}

                        <Label variant="primary">Project Tags</Label>
                        <Label variant="secondary" css={{ mb: '10px' }}>
                            Populate your projects tags using one of your existing projects, or leave the selection
                            blank to start from scratch.
                        </Label>
                        <FlexRow css={{ gap: '4px', maxHeight: '40px', height: '40px' }}>
                            <TagsSelector
                                data-cy="select-project-tags"
                                tags={filteredTags}
                                selectedTag={selectedTag}
                                onTagSelected={handleTagSelected}
                                inputValue={tagSearchValue}
                                setInputValue={setTagSearchValue}
                            />
                        </FlexRow>

                        <Label variant="primary">Project Statuses</Label>
                        <Label variant="secondary" css={{ mb: '10px' }}>
                            Pin statuses represent the state of a task pin. Start with the default statuses, then
                            customise, or use statuses from an existing project.
                        </Label>

                        <FlexRow css={{ gap: '4px', maxHeight: '40px', height: '40px', marginBottom: '8px' }}>
                            <Select300
                                data-cy="select-statuses"
                                options={statusSourceOptions}
                                value={statusSource}
                                onValueChange={setStatusSource}
                            />
                        </FlexRow>
                    </StyledLeftPanelTopBoxContent>
                </ScrollArea>
            </StyledLeftPanelTopBox>

            <PanelActions primaryText="Next" onPrimaryAction={isDataMissing ? () => setShowErrors(true) : onNext} />
        </StyledLeftPanelBox>
    )
}

LeftPanel.propTypes = {
    address: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    projectType: PropTypes.string,
    tagSourceOptions: Select.optionsShape,
    tagSource: PropTypes.string,
    statusSource: PropTypes.string,
    statusSourceOptions: Select.optionsShape,

    nameError: PropTypes.string,
    addressError: PropTypes.string,
    projectTypeError: PropTypes.string,

    setAddress: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setProjectType: PropTypes.func.isRequired,
    setTagSource: PropTypes.func.isRequired,
    setStatusSource: PropTypes.func.isRequired,
}

// ---------------------------------------------------------------------------------------------------------------------
// NewProjectPage1 (UI, not attached to Redux)
// ---------------------------------------------------------------------------------------------------------------------
const NewProjectPage1 = props => {
    const { center, setCenter, ...lhsProps } = props
    const { address, setAddress } = lhsProps

    const place = { point: center, name: address }

    const setPlace = place => {
        setCenter(place.point)
        setAddress(place.name)
    }

    return (
        <FlexRow css={{ backgroundColor: '$neutral09', height: '100vh', overflow: 'hidden' }}>
            <LeftPanel {...lhsProps} />
            <SinglePlaceMap
                css={{ width: '100vw', height: '100vh' }}
                mapCenter={center || [0, 0]}
                mapZoom={center ? 14 : 1} // if there's a center point zoom in; otherwise entire earth
                place={place}
                setPlace={setPlace}
                addressContainerId={ADDRESS_CONTAINER_ID}
            />
        </FlexRow>
    )
}

NewProjectPage1.propTypes = {
    address: PropTypes.string,
    center: PropTypes.arrayOf(PropTypes.number),
    description: PropTypes.string,
    name: PropTypes.string,
    projectType: PropTypes.string,
    tagSource: PropTypes.string,

    onCancel: PropTypes.func,
    onNext: PropTypes.func,

    setAddress: PropTypes.func.isRequired,
    setCenter: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setProjectType: PropTypes.func.isRequired,
    setTagSource: PropTypes.func.isRequired,
}

export { NO_ID }
export default NewProjectPage1
