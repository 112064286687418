import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw-unminified.js'
import { Geometry } from '@range.io/basic-types'
import * as F from '@range.io/functional'
import { equals, mergeRight } from '@range.io/functional'
import bearing from '@turf/bearing'
import React, { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { v4 } from 'uuid'
import { useKeyMaps } from '../components-reusable/hooks/index.js'
import { GeometriesAddedCommand, GeometriesChangedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import DrawArrowMode from './mapbox-draw-modes/draw-arrow-mode.js'
import DrawLineMode from './mapbox-draw-modes/draw-line-mode.js'
import DrawPhotoMarkerMode from './mapbox-draw-modes/draw-photo-marker-mode.js'
import DrawPolygonMode from './mapbox-draw-modes/draw-polygon-mode.js'
import DrawPolyline from './mapbox-draw-modes/draw-polyline-mode.js'
import DrawRectangleMode from './mapbox-draw-modes/draw-rectangle-mode.js'
import { DrawMarkerMode, DrawTextMode } from './mapbox-draw-modes/draw-symbol-modes.js'
import IdleMode from './mapbox-draw-modes/idle-mode.js'
import MultiSelectMode from './mapbox-draw-modes/multi-select-mode.js'
import SelectArrowMode from './mapbox-draw-modes/select-arrow-mode.js'
import SelectMode from './mapbox-draw-modes/select-mode.js'
import SelectRectangleMode from './mapbox-draw-modes/select-rectangle-mode.js'
import SelectSymbolMode from './mapbox-draw-modes/select-symbol-mode.js'
import mapboxGlDrawStyle from './mapbox-gl-draw-style.js'

/*
 * When we read an arrow, we get only the line; we need to create the arrow head rotated to align with the arrow line
 * @sig createArrowHead :: (Id, Id, [[Number]]) -> GeoJSONFeature
 */
const createArrowHead = (id, arrowLine) => {
    const [startPoint, endPoint] = arrowLine.geometry.coordinates
    return {
        id,
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: endPoint,
        },
        properties: {
            annotationType: 'arrow',
            rotation: bearing(startPoint, endPoint),
            parentFeatureId: arrowLine.id,
        },
    }
}

const MapboxDrawingCanvas = props => {
    const { mapboxMap, mode, selectedTool, children, onSelectionChange } = props
    const [draw, setDraw] = useState()
    const { dispatch, getState } = useStore()
    const { runCommand, resourceChanged, resourceRemoved } = useCommandHistory()

    // Select which mapbox-gl-draw control buttons to add to the map.
    // Set mapbox-gl-draw to draw by default.
    // The user does not have to click the polygon control button first.
    const initialize = () => {
        if (!mapboxMap) return

        // -------------------------------------------------------------------------------------------------------------
        // HACK: monkey-patchDraw's ModeInterface.newFeature method to create a UUID id rather than a hat id
        // (hat is a different GUID generator)
        const MonkeyPatchNewFeatureId = {}
        MonkeyPatchNewFeatureId.onSetup = function () {
            const prototype = Object.getPrototypeOf(this)

            // wrap original version in a function that creates a uuid for the geojson object
            const originalVersionOfNewFeature = prototype.newFeature
            prototype.newFeature = geojson => {
                geojson.id = v4()
                return originalVersionOfNewFeature.call(this, geojson)
            }

            // return to the real mode
            // HACK: setTimeout: need to delay change back to idle or it won't work and
            // MonkeyPatchNewFeatureId.toDisplayFeatures will be used to draw (and will draw nothing)
            const timeout = setTimeout(() => draw.changeMode('idle'))

            // When the MapboxDrawingCanvas component is unmounted later, we'll need to reset newFeature
            // to avoid creating a closure holding draw object that will receive events after it's dead
            MonkeyPatchNewFeatureId.removeMonkeyPatch = () => {
                clearTimeout(timeout) // otherwise we risk calling draw.changeMode at the wrong time
                return (prototype.newFeature = originalVersionOfNewFeature)
            }
        }
        MonkeyPatchNewFeatureId.toDisplayFeatures = function (state, geojson, display) {}
        // End HACK
        // -------------------------------------------------------------------------------------------------------------

        // initialize MapboxDraw
        const draw = new MapboxDraw({
            defaultMode: 'idle',
            userProperties: true, // needed to be able to style things based on properties
            modes: {
                idle: IdleMode,
                draw_line: DrawLineMode,
                draw_polyline: DrawPolyline,
                draw_arrow: DrawArrowMode,
                draw_polygon: DrawPolygonMode,
                draw_rectangle: DrawRectangleMode,
                draw_marker: DrawMarkerMode,
                draw_text: DrawTextMode,
                draw_photo_marker: DrawPhotoMarkerMode,
                draw_task_marker: DrawPhotoMarkerMode, // exists only to get cursor right in GeometrySlice toolToState
                select: SelectMode,
                select_arrow: SelectArrowMode,
                select_rectangle: SelectRectangleMode,
                select_symbol: SelectSymbolMode,
                multi_select: MultiSelectMode,
                monkeyPatchNewFeatureId: MonkeyPatchNewFeatureId,
            },
            styles: mapboxGlDrawStyle,
            displayControlsDefault: false,

            // required for the delete key to delete things
            // commented out for now because our pins shouldn't be deleted lightly
            // controls: { trash: true },
        })

        // stow draw for use outside initialize
        setDraw(draw)
        mapboxMap.addControl(draw, 'top-left')

        // HACK: monkey-patch (one time only)
        draw.changeMode('monkeyPatchNewFeatureId')

        // facade to get to expose functions on MapboxDraw object
        MapboxDrawingCanvas.getFeature = featureId => draw.get(featureId)

        // save the ids of the selected GeoJSON Features
        // triggers a redraw even if the selected features haven't changed because pluck returns a new array each time
        const selectionChange = async e => {
            await dispatch(ReduxActions.selectedGeometryIdsChanged(F.pluck('id', e.features)))
            onSelectionChange()
        }

        // :: [GeoJSON] -> [Geometry]
        const geoJsonsToGeometries = geoJsons => {
            // we DON'T want to store an arrow head, so filter those out before converting
            const isArrowHead = json => json.properties.annotationType === 'arrow' && json.geometry.type === 'Point'
            geoJsons = F.reject(isArrowHead, geoJsons)

            const canvas = ReduxSelectors.selectedCanvas(getState())
            return geoJsons.map(geoJson => Geometry.fromGeoJson(geoJson, canvas.id))
        }

        const geometriesChanged = e => runCommand(GeometriesChangedCommand.Outbound(geoJsonsToGeometries(e.features)))
        const geometriesAdded = e => {
            // For now: just get the FIRST geometry
            if (e.features.length !== 1) throw new Error('Expecting exactly one geometry')

            const mapDrawFeature = e.features[0]
            const isTask = mapDrawFeature.properties.isTask
            const geometries = geoJsonsToGeometries(e.features)

            // should focus the collab window
            dispatch(ReduxActions.setFocusCollabWindow(true))

            return runCommand(GeometriesAddedCommand.Outbound(geometries[0], isTask))
        }

        // helper to make sure Mapbox Draw knows there are geometries to select
        const selectGeometriesIfNeeded = () => {
            // this checks if there's a selected collaboration, it requires that all collaboration data is loaded so we have the feature and geometry if this is not null
            const selectedCollaboration = ReduxSelectors.selectedCollaboration(getState())
            if (selectedCollaboration) {
                const geometryForSelectedCollaboration = ReduxSelectors.geometryForCollaboration(
                    getState(),
                    selectedCollaboration
                )
                selectFeature(geometryForSelectedCollaboration.id)
            }
        }

        mapboxMap.on('draw.modechange', e => dispatch(ReduxActions.drawingModeChanged(e.mode)))
        mapboxMap.on('draw.selectionchange', selectionChange)
        mapboxMap.on('draw.create', geometriesAdded)
        mapboxMap.on('draw.update', geometriesChanged)
        mapboxMap.on('draw.refresh', selectGeometriesIfNeeded)

        /*
         * A Geometry doesn't necessarily include all the data we want to display on the map,
         * so in addition to retrieving the Geometry asGeoJson we may also need to set additional properties
         * NOTE: mapbox-gl-draw-style controls which fields is displayed and when
         */
        const enrichGeometryToGeoJson = geometry => {
            const result = Geometry.asGeoJson(geometry)

            // console.log('## enrichGeometryToGeoJson', geometry, result)

            // When we add a PhotoMarker, we compute its iconImage and selectedIconImage (see PhotoMarkerController)
            // When we receive the same feature AGAIN from Firebase, we need these values
            if (geometry.annotationType === 'photoMarker') {
                const oldGeometry = draw.get(geometry.id)
                if (oldGeometry) result.properties = mergeRight(oldGeometry.properties, result.properties)
            }

            // this is an example: annotationType 'text' requires a text property (but cannot have an 'icon' property)
            // in fact this is a bad example, since:
            //   - changing the collaboration's name will not immediately update the text marker, since
            //     right now Geometries don't get a message when their collaboration changes
            if (geometry.annotationType === 'text') {
                const collab = ReduxSelectors.firstCollaborationForGeometry(getState(), geometry.id)
                return F.assocPath(['properties', 'text'], collab.name || 'untitled', result)
            }

            // Only the line of an arrow is persisted to our database -- but we use two GeoJSON features
            // to draw an arrow, so we need to create a GeoJSON Point for the arrow and point the line
            // and head at each other using parentFeatureId (in the head) and arrowFeatureId (in the line)
            if (geometry.annotationType === 'arrow') {
                const _updateArrowHead = () => {
                    const [startPoint, endPoint] = result.geometry.coordinates

                    // update the position and bearing of the head
                    const arrowHead = F.mergeDeepRight(draw.get(arrowHeadId), {
                        geometry: { coordinates: endPoint },
                        properties: { rotation: bearing(startPoint, endPoint) },
                    })

                    return [arrowLine, arrowHead]
                }
                const _createArrowHead = () => {
                    const id = v4()
                    const updatedArrowLine = F.assocPathString('properties.arrowFeatureId', id, arrowLine)
                    const arrowHead = createArrowHead(id, arrowLine)
                    return [updatedArrowLine, arrowHead]
                }

                // get or create the arrow head
                const arrowLine = F.mergeDeepRight(draw.get(result.id), result)
                const arrowHeadId = arrowLine?.properties?.arrowFeatureId
                return arrowHeadId ? _updateArrowHead() : _createArrowHead()
            }

            return result
        }

        // -------------------------------------------------------------------------------------------------------------
        // Incoming changes to Redux state which have to be propagated to draw
        // -------------------------------------------------------------------------------------------------------------

        // add each of the passed-in geometries that is on the current canvas
        const addGeometriesOnSelectedCanvasOnly = geometries => {
            const selectedCanvas = ReduxSelectors.selectedCanvas(getState())
            geometries = geometries.filter(g => g.canvasId === selectedCanvas?.id)

            const features = { type: 'FeatureCollection', features: geometries.flatMap(enrichGeometryToGeoJson) }
            draw.add(features)

            selectGeometriesIfNeeded()
        }

        const selectFeature = featureId => {
            // don't reselect the same thing
            if (!draw.get(featureId)) return
            if (equals(draw.getSelectedIds(), [featureId])) return

            draw.changeMode('select', { featureId })
        }

        draw.addGeometriesOnSelectedCanvasOnly = addGeometriesOnSelectedCanvasOnly
        draw.selectFeature = selectFeature
        draw.clearSelection = () => setTimeout(() => draw.changeMode('idle'))

        resourceChanged('mapboxMap', mapboxMap)
        resourceChanged('mapboxDraw', draw)

        // MapboxDrawingCanvas component was unmounted
        return () => {
            MonkeyPatchNewFeatureId.removeMonkeyPatch()
            setDraw(undefined)
            resourceRemoved('mapboxMap')
            resourceRemoved('mapboxDraw')
        }
    }

    const revertToIdleMode = () => dispatch(ReduxActions.drawingModeChanged('idle'))

    const modeChanged = () => {
        if (!draw || draw.getMode() === mode) return

        // escape key reverts to idle mode
        if (selectedTool !== 'none') pushKeyMap('MapboxDrawingCanvas', { Escape: revertToIdleMode })
        else popKeyMap('MapboxDrawingCanvas')

        draw.changeMode(mode, { selectedTool })
    }

    useEffect(initialize, [mapboxMap])
    useEffect(modeChanged, [mode])
    const { pushKeyMap, popKeyMap } = useKeyMaps()

    // pass on Mapbox and Mapbox Draw instances to all children
    const renderChildrenWithMapboxDraw = children => {
        const renderChildWithMapboxDraw = child => {
            if (!child) return null

            return React.cloneElement(child, {
                ...child.props,
                mapboxDraw: draw,
                mapboxMap,
            })
        }
        return React.Children.map(children, renderChildWithMapboxDraw)
    }

    return renderChildrenWithMapboxDraw(children) ?? null
}

export default MapboxDrawingCanvas
